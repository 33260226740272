export const PATH_ACCUEIL = "/reservation";
export const PATH_ACHAT_FORFAIT = "/achat-forfait";
export const PATH_ACTIVITE_RESERVATION = "/activité-réservation";
export const PATH_BOOKING = "/booking";
export const PATH_BOUTIQUE = "/boutique";
export const PATH_BILLETTERIE = "/billetterie";
export const PATH_BILLETTERIE_UNITE = "/billetterie/unite";
export const PATH_CACHE = "/cache";
export const PATH_CHOIX_UNITE = "/choix-unite";
export const PATH_CHOIX_UNITE_CALENDRIER = "/calendrier";
export const PATH_CHOIX_TYPE_UNITE = "/choix-type-unite";
export const PATH_CREER_COMPTE = "/profile/nouveau";
export const PATH_DROIT_ACCES = "/droit-acces";
export const PATH_CAMPS_RESERVATION = "/reservation-camps";
export const PATH_LECTEUR_CARTES_MEMBRES = "/lecteur-cartes-membres";
export const PATH_PAIEMENT = "/paiement";
export const PATH_PAIEMENT_COMPTES = "/paiement-comptes";
export const PATH_PANIER = "/panier";
export const PATH_PROFILE = "/profile";
export const PATH_RECHERCHE = "/recherche";
export const PATH_RESERVATION = "/reservation";
export const PATH_KIOSQUE = "/kiosque";
export const PATH_ANNULER_RESERVATION = "/annuler-reservation";
export const PATH_RESTAURANT = "/restaurant";
export const PATH_TIRAGE = "/tirage";
export const PATH_TERRAIN = "/terrain";
export const PATH_ACTIVITE = '/activites';
export const PATH_FERMER_ENREGISTREMENT = "/fermer-enregistrement";
export const PATH_FERMER_ENREGISTREMENT_CAPTURE = "/fermer-enregistrement-capture"
export const PATH_EVENEMENT = '/evenement'