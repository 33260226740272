import React, {useState, useEffect, Fragment} from 'react';
import {connect} from "react-redux";
import withNavigation from "../higher-order/navigation";
import "./camps-page.css";
import {getBilletterie, getQuantiteEnMain, getAutobusList, getMunicipaliteList} from "../../server/services/catalogue-service";
import {getTrancheAge} from "../../server/services/trancheage";
import SommaireRightPanel from "../achat-forfait/sommaire-right-panel";
import {getShop} from '../actions/shop';
import {PATH_PANIER} from '../../constants/navigation-constants';
import {addToShoppingCart, updateShop, CartItemType, setErrorAlert} from '../actions/shop';
import {useSeoTitle} from "../../utils/hooks";
import CartSvg from '../svg/cart';
import LoadingSvg from "../svg/loading.js";
import BlockedSvg from '../svg/blocked.js';
import CampDetails from './camp-details';
import PreviousSvg from '../svg/previous';
import {Route} from "react-router-dom";
import * as navigation from "../../constants/navigation-constants";
import moment from "moment";
import Chandail from "./chandail";

function CampsPage(props) {
    useSeoTitle("Camp sélectionné");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [etape, setEtape] = useState(0);
    const [camps, setCamps] = useState([]);
    // const [camps, setCamps] = useState(data);
    const [busOrigine, setBusOrigine] = useState([]);
    const [busFilteredByAge, setBusFilteredByAge] = useState({});
    const [trajets, setTrajets] = useState({});
    const [age, setAge] = useState(0);
    const [municipalite, setMunicipalite] = useState('');
    const dest = ['Boileau', 'Bowman', 'Chénéville', 'Duhamel', 'Fassett', 'Lac-des-Plages', 'Lac-Simon', 'Lochaber (Canton)', 'Lochaber-Partie-Ouest', 'Mayo', 'Montebello', 'Montpellier', 'Mulgrave-et-Derry', 'Namur', 'Notre-Dame-de-Bonsecours', 'Notre-Dame-de-la-Paix', 'Notre-Dame-de-la-Salette', 'Papineauville', 'Plaisance', 'Ripon', 'Saint-André-Avellin', 'Saint-Émile-de-Suffolk', 'Saint-Sixte', 'Thurso', 'Val-des-Bois',]
    const PARAM_IDZEC_LANGUE = "/:idZec/:language";
    const [municipalites, setMunicipalites] = useState([])

    useEffect(() => {
        getMunicipaliteList().then((list) => {
            setMunicipalites(list);
            if (props.shop.cart.length > 0) {
                props.shop.cart.forEach(item => {
                    if (item.type === CartItemType.CampJour) {
                        setMunicipalite(item.idMunicipalite)
                        setAge(item.age);
                    }
                })
            }
        });
    }, []);

    useEffect(async () => {
        if (!municipalite) return;
        getAutobusList(municipalite).then((busData) => {
            setBusOrigine(busData);
            const filtedBus = busData.filter(b => age >= b.ageDebut && age <= b.ageFin);
            // const filtedBus = busData.filter(b => b.placesRestantesAge && b.placesRestantesSemaine && b.placesRestantesTrajet);
            const groupedBusByWeeks = setTrajetsBySemaines(busData, filtedBus);
            if (props.shop.cart.length > 0) {
                props.shop.cart.forEach(item => {
                    if (item.type === CartItemType.CampJour) {

                        item.trajetsAutobus.forEach(p => {
                            if (groupedBusByWeeks[p.semaine][0])
                                groupedBusByWeeks[p.semaine][0]["selected"] = p.trajet;
                        });
                    }
                })
            }
            setBusFilteredByAge(groupedBusByWeeks);
            setDataLoaded(true);
        })
    }, [municipalite, age])

    const setTrajetsBySemaines = (busData, filtedBus) => {
        const trajets = {}
        busData.forEach(e => {
            e.selected = '';
            if (!trajets[e.semaine]) {
                trajets[e.semaine] = [];
            }
            if (e.placesRestantesAge && e.placesRestantesSemaine && e.placesRestantesTrajet && !trajets[e.semaine].includes(e.trajet)) {
                trajets[e.semaine].push(e.trajet);
            }
        });
        setTrajets(trajets);

        //console.log(trajets)
        const groupedBusByWeeks = {...trajets};
        //console.log(groupedBusByWeeks)
        Object.keys(groupedBusByWeeks).forEach(function (key, index) {
            groupedBusByWeeks[key] = []
            filtedBus.forEach(b => {
                if (groupedBusByWeeks[key].length === 0 && b.semaine === key && b.placesRestantesAge && b.placesRestantesSemaine && b.placesRestantesTrajet) {

                    groupedBusByWeeks[key].push(b)
                }
            });
        });
        return groupedBusByWeeks;
    }

    const confirm = async () => {
        if (!age || !municipalite) {
            props.setErrorAlert('Veuillez sélectionner un âge et une municipalité afin de continuer.');
            return;
        }
        if (!busFilteredByAge) {
            props.setErrorAlert('Une erreur est survenue.');
            return;
        }
        let trajetChoisi;
        Object.keys(busFilteredByAge).map((week, index) => {
            if (busFilteredByAge?.[week]?.[0] && busFilteredByAge?.[week]?.[0].selected) trajetChoisi = true;
        });

        if (!trajetChoisi) {
            props.setErrorAlert('Veuillez sélectionner au moins un trajet afin de continuer.');
            return;
        }
        const selectedMunicipalite = municipalites.find(f => f.idMunicipalite === municipalite)?.description || "";
        let newItem = {
            id: "inscriptionCampJour", // id utilisé pour identifier l'item dans le cart
            trajetsAutobus: [], age: age, idMunicipalite: municipalite, formulaire: {}, name: `Inscription au camp de jour`, type: CartItemType.CampJour, inclus: [{name: "Âge", value: age}, {name: "Municipalité", value: selectedMunicipalite}]
        };
        let montant = 0;
        Object.keys(busFilteredByAge).forEach(week => {
            if (busFilteredByAge?.[week]?.[0] && busFilteredByAge?.[week]?.[0].selected) {
                const selectSemaine = busFilteredByAge[week][0];
                const selectdBus = busOrigine.find(f => {
                    return f.semaine === selectSemaine.semaine && Number(age) >= f.ageDebut && Number(age) <= f.ageFin && f.trajet === selectSemaine.selected
                });
                const isTrajetChoisi = selectdBus.idProduitTrajet !== '00000000-0000-0000-0000-000000000000';
                montant += selectdBus.prix;
                newItem.trajetsAutobus = [...newItem.trajetsAutobus, selectdBus];
                newItem.inclus = [...newItem.inclus, {name: `${ selectdBus.semaine }`, value: isTrajetChoisi ? selectSemaine.selected : ""}]
            }
        });

        newItem.montant = Number(montant);
        newItem.taxableTps = false;
        newItem.taxableTvq = false;
        newItem.total = Number(newItem.montant) + Number(newItem.tps) + Number(newItem.tvq);
        const cartIndex = props.shop.cart.findIndex(s => s.type === CartItemType.CampJour)
        if (cartIndex < 0) {
            await props.addToShoppingCart(newItem, false);
        } else {
            await props.updateShop(cartIndex, newItem, false);
        }
        props.history.push({pathname: `/${ props.match.params.idZec }/${ props.match.params.language || "fr" }/reservation-camps/formulaire`});
    }

    const onChange = (e, index) => {
        // if (e.target.value > 9 || e.target.value < 0) return
        const newCamps = [...camps];
        newCamps[index] = e.target.value;
        setCamps(newCamps);
    }

    const onChangeAge = (e) => {
        let val = e.target.value;
        if (val > 150 || val < 0) {
            return;
        }
        setDataLoaded(false);
        val = val.replace(/[^0-9]/g, '');
        val = val.replace(/^0+/, '');

        const filtedBus = busOrigine.filter(b => val >= b.ageDebut && val <= b.ageFin);
        // const filtedBus = busOrigine.filter(b => val >= b.ageDebut && val <= b.ageFin && b.placesRestantesAge && b.placesRestantesSemaine && b.placesRestantesTrajet);
        const groupedBusByWeeks = setTrajetsBySemaines(busOrigine, filtedBus);
        setBusFilteredByAge(groupedBusByWeeks);
        setAge(val);
        setDataLoaded(true);
        // const re = /^[0-9\b]+$/;
        // if (re.test(e.target.value)) {
        //   setAge(e.target.value);
        // }
    }

    const RightDiv = ({e, week, className}) => {
        return (trajets[e.semaine].length ? (<div className={ className }>
            <label htmlFor="input2">Quel arrêt d’autobus ?</label>
            <select
                value={ busFilteredByAge?.[week]?.[0]?.selected }
                onChange={ (e) => {
                    setBusFilteredByAge(prevState => {
                        const newState = {...prevState};
                        newState[week][0].selected = e.target.value;
                        return newState;
                    });
                } }
            >
                <option id='none' value=''>CHOISIR</option>
                { (trajets[e.semaine] || []).map((stop, i) => (<option key={ i } value={ stop }
                >
                    { stop.toUpperCase() }
                </option>)) }
            </select>
        </div>) : (<div className={ className } style={ {alignSelf: 'center'} }>
            COMPLET
        </div>));
    };

    return (<>

        <div id="reservation-camps-page">
            <div className="achat-forfait-card-container">
                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_CAMPS_RESERVATION }` } exact={ true }>
                    <>
                        <div className='info-camp'>
                            <div className="display-inline-block">
                                <div style={ {display: 'inline-block'} }>
                                    <span className="profile-span">ÂGE :</span>
                                    <input type="text" className='camp-age-input'
                                           type="input" value={ age }
                                           onChange={ (e) => onChangeAge(e) }
                                    />
                                </div>
                                <div style={ {display: 'inline-block'} }>
                                    <span className="profile-span">MUNICIPALITÉ :</span>
                                    <select value={ municipalite }
                                            className='camp-municipalite-input'
                                            onChange={ (e) => setMunicipalite(e.target.value) }>
                                        <option id='none' value=''>CHOISIR</option>
                                        { (municipalites || []).map((e, i) => (<option key={ e.idMunicipalite } value={ e.idMunicipalite }>
                                            { e.description.toUpperCase() }
                                        </option>)) }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            { age && Object.keys(busFilteredByAge).length > 0 ? Object.keys(busFilteredByAge).map((week, index) => (<Fragment key={ index }>
                                { busFilteredByAge[week].length ? busFilteredByAge[week].map((e, tripIndex) => (<div className="small-box" key={ tripIndex }>
                                    <div className="container">
                                        <div className="box"
                                             style={ {flex: 'none'} }
                                        >
                                            <input type="checkbox"
                                                   checked={ busFilteredByAge?.[week]?.[0]?.selected }
                                                   onChange={ () => {
                                                       if (busFilteredByAge?.[week]?.[0]?.selected) {
                                                           setBusFilteredByAge(prevState => {
                                                               const newState = {...prevState};
                                                               newState[week][0].selected = '';
                                                               return newState;
                                                           });
                                                       }
                                                       else if(trajets[e.semaine].length===1){
                                                           setBusFilteredByAge(prevState => {
                                                               const newState = {...prevState};
                                                               newState[week][0].selected = trajets[e.semaine][0];
                                                               return newState;
                                                           });
                                                       }
                                                   } }
                                                   readOnly/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span
                                                className="profile-span"
                                                style={ {
                                                    fontSize: '25px'
                                                } }>
                                  { e.semaine }
                                </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                  { e.dateDebut ? `${ moment(e.dateDebut).format("yyyy-MM-DD") } au ${ moment(e.dateFin).format("yyyy-MM-DD") }` : "" }
                                </span>
                                        </div>
                                        <RightDiv e={ e } week={ week } className='input-group'/>
                                    </div>
                                    <RightDiv e={ e } week={ week } className='destination-small'/>
                                </div>)) : <div className="small-box" key={ week }>
                                    <div className="container">
                                        <div className="box"
                                             style={ {flex: 'none'} }
                                        >
                                            <BlockedSvg/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span
                                                className="profile-span"
                                                style={ {
                                                    fontSize: '25px'
                                                } }>
                                { week }
                              </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div className="input-group"
                                             style={ {alignSelf: 'center'} }
                                        >
                                            AUCUNE DISPONIBILITÉ POUR CETTE SEMAINE
                                        </div>
                                    </div>

                                    <div className="destination-small">
                                        AUCUNE DISPONIBILITÉ POUR CETTE SEMAINE
                                    </div>
                                </div> }

                            </Fragment>)) : <span className="boutique-page-no-result">
                    { dataLoaded ? <h2>VEUILLEZ ENTRER UN ÂGE</h2> : <h2><LoadingSvg/> CHARGEMENT DES DONNÉES…</h2> }
                  </span> }
                        </div>
                        <div className="billet-unite-achat-btn unselectable billetterie-page-retour-btn ">
                            <span className={ `clickable  ${ (!municipalite || !age) && 'off' } right` } onClick={ confirm }>SUIVANT</span>
                        </div>
                    </>
                </Route>
                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_CAMPS_RESERVATION }/formulaire` }>
                    <CampDetails onChange={ onChange } setEtape={ setEtape }/>
                </Route>
                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_CAMPS_RESERVATION }/produit` }>
                    <Chandail/>
                </Route>
            </div>
            <div className="sommaire-right-panel max-width sommaire-large-screen">
                <SommaireRightPanel
                    allowDelete={ false }
                    message="FACTURE"
                    showConfirm={ false }
                    confirm={ () => props.goTo("/panier", "PANIER") }
                    confirmTitle='Aller au panier'
                    confirmError='Ajouter un item'
                    canConfirm={ props.shop.cart.length > 0 }
                />
            </div>
        </div>
    </>);
}

const mapStateToProps = (state, ownProps) => ({
    shop: state.shop, entrepriseName: state.entreprise.nom
});

const dispatchProps = {
    getShop, addToShoppingCart, updateShop, setErrorAlert
};

export default withNavigation(connect(mapStateToProps, dispatchProps)(CampsPage));