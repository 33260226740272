import * as dateFns from "date-fns";
import PropTypes from "prop-types";
import FetchApi, {post} from "../../server/api.js";
import React, {Component, useEffect, useState} from "react";
import {getUniteSearchFields, getGroupSearch, getOptionsReservation} from "../../server/services/unite-service";
import {addReservationTemp, calculateSumarryAmounts} from "../../server/services/reservation-service";
import {validateSearchOptions} from "../../server/services/unite-service";
import {connect} from "react-redux";
import {parse as parseDate, addDays, parse, getHours, format as formatDate, differenceInDays, parseJSON} from "date-fns";
import {toSelectedISODateTimeString, toDateTimeString, toDateString} from "../../utils/formatting";
import ChoixRecherchePanel from "../choix-recherche-panel";
import {addToShoppingCart, setErrorAlert, setTimeoutShop} from "../actions/shop";
import SpinInput from "../inputs/SpinInput";
import ChoixUniteListe from "../choix-unite-liste";
import {TypeAuxiliaire} from "../../enums/TypeAuxiliaire";
import {getProductById, getProductPrixParTrancheAge} from "../../server/services/catalogue-service";
import ChampDeRechercheFieldError from "./champ-de-recherche-field-error";
import DemanderTrancheAge from "../popups/demander-tranche-age";
import OptionReservationModeCalcul from "../../enums/OptionReservationModeCalcul";
import CatalogueProduitsPopup from "../popups/catalogue-produits-popup";
import ConfirmationOptionReservation from "../popups/confirmation-option-reservation-auto";
import Calendar from "../calendar";
import {getSearchFieldsExtra} from "../../server/services/parametre-reservation-en-ligne";
import Translator from "../translator";
import withNavigation from "../higher-order/navigation";
import {PATH_PANIER} from "../../constants/navigation-constants";

const TerrainPage = (props) => {
    const [state, setState] = useState(
        {
            previewArrivee: '',
            previewDepart: '',
            unites: [],
            maxJoursEnAvanceReservation: '',
            dataLoadedChampsRecherches: true, //false
            dataLoadedUnites: false,
            isCalenderDateChange: false,
            isSearchOption: false,
            isCalendarBlurred: false,
            initialLocation: props.location.pathname,
            dataLoadedConfigs: false,
            dataLoadedParametres: false,
            showCalendrierArrivee: false,
            showCalendrierDepart: false,
            typeHebergement: props.getTypeHebergement(),
            cartePathname: "",
            listePathname: "",
            recherchePathname: "",
            unitePathname: "",
            activitePathname: "",
            carte: '',
            AjouterPanierEtape: 0,
            searchOptions: {
                selectedArrivee: '',
                selectedDepart: '',
                selectedIdEquipement: "",
                selectedLongueur: "",
                // selectedService: -1, // -1: tous les services
                // selectedIdAmperage: "",
                selectedAdultes: 0,
                selectedEnfants: 0,
                selectedTrancheAges: [],
                extra: [],
                selectedEntreeSortie: false
            },
            includedOptions: [],
            selectedProducts: [],
            fieldError: {},
            calendarDateInSelection: false,
            isSearchPopupOpen: false,
            demandeDeReservationParCourriel: false,
            consultationSeulement: false,
            isTrancheAgePopupOpen: false,
            isOptionsReservaitonPopupOpen: false,
            OptionsReservaitonPopupResult: false,
            produitsOptionnelsPopup: false,
            peutAjouterPanier: false
        }
    );

    const [uniteSelected, setUniteSelected] = useState(null)
    const [noGroup, setNoGroup] = useState('');
    const TEXTS = Translator(props.language, "TXT_CHAMP_DE_RECHERCHE");
    const mandatoryInputError = " mandatory-input-error";
    const NULLDATE = new Date(1900, 1, 1, 0, 0, 0);

    useEffect(async () => {
        if (!uniteSelected || state.AjouterPanierEtape === 0 || state.isTrancheAgePopupOpen || state.produitsOptionnelsPopup || state.isOptionsReservaitonPopupOpen) return;

        if (state.AjouterPanierEtape < 3) {
            await PrepareForAddToShoppingCart(uniteSelected);
        } else if (state.AjouterPanierEtape >= 3) {
            addToShoppingCart(uniteSelected).then((error) => {
                if (error)
                    props.goTo(PATH_PANIER)
                else {
                    setState(prev => {
                        return {
                            ...prev,
                            AjouterPanierEtape: 0,
                            isTrancheAgePopupOpen: false,
                            produitsOptionnelsPopup: false,
                            isOptionsReservaitonPopupOpen: false,
                        }
                    })
                }
            });
        }
    }, [state.AjouterPanierEtape, uniteSelected, state.isTrancheAgePopupOpen, state.isOptionsReservaitonPopupOpen, state.produitsOptionnelsPopup])

    // const params = new URLSearchParams(props.location.search);
    // const [setState, state] = useState({
    //   searchOptions: {
    //     selectedIdEquipement: params.get("idUniteEquipmentType") || "",
    //   }
    // });

    const TypeHebergement = {
        A: "Reservation Activité", // Voir page-reservation
        AC: "Reservation Activité",
        CP: "Reservation Camping",
        PC: "Reservation Hebergement",
        CH: "Reservation Hebergement"
    };
    const {
        idTypeUnite,
        typeHebergement
    } = props;
    const [configFetched, setConfigFetched] = useState(false);
    const [searchField, setSearchField] = useState({
        equipements: [],
        longueursEquipements: [],
    });
    const [askOption, setAskOption] = useState({
        askEquipement: false,
        askLengthEquip: false,
    });

    useEffect(async () => {
        await setSearchOptions();

        // const params = getSearchSyncedWithGetParams();
        // if (params.noGroupe && params.noGroupe) {
        //   let url = '';
        //   if (params.noConfirmation) {
        //     // setNoConfirm(params.noConfirmation);
        //     url += `?NoConfirmation=${params.noConfirmation}`;
        //   }
        //   if (params.noGroupe) {
        //     // setNoGroup(params.noGroupe);
        //     url += `&NoGroupe=${params.noGroupe}`;
        //   }
        //   if (params.selectedIdEquipement) {
        //     // setSelectedEquipement(params.selectedIdEquipement);
        //     url += `&IdUniteEquipmentType=${params.selectedIdEquipement}`;
        //   }
        //   if (state.searchOptions.selectedLongueur) {
        //     // setSelectedLongueur(params.selectedLongueur);
        //     url += `&LongueurEquipement=${state.searchOptions.selectedLongueur}`;
        //   }
        //   applySearchFilters(false);
        // }
    }, []);

    useEffect(() => {
        // if (!idTypeUnite && !typeHebergement) return;
        getUniteSearchFields(idTypeUnite, "CP").then((champsRecherche) => {
            setSearchField({
                equipements: champsRecherche.equipements,
                longueursEquipements: champsRecherche.longueursEquipement
            });
        });
    }, [idTypeUnite, typeHebergement]);

    const getSearchSyncedWithGetParams = () => {
        const params = new URLSearchParams(props.location.search);
        return {
            noConfirmation: params.get("NoConfirmation") || "",
            noGroupe: params.get("NoGroupe") || "",
            selectedIdEquipement: params.get("IdUniteEquipmentType") || "",
            selectedLongueur: params.get("LongueurEquipement") || ""
        };
    }

    const setSearchOptions = async () => {
        let champRecherche = null;
        getUniteSearchFields(null, state.typeHebergement).then((champsRecherche) => {
            champRecherche = champsRecherche;
            // setState({ ...state, searchFields: champsRecherche });
        });

        const params = new URLSearchParams(props.location.search.toLowerCase());
        let unites = [];
        if (params.get("noconfirmation") != '' && params.get("nogroupe") != '') {
            unites = await fetchUnites(params);
        }

        setState((prevState) => {
            return {
                ...prevState,
                searchFields: champRecherche,
                unites: unites,
                searchOptions: {
                    ...prevState.searchOptions,
                    selectedArrivee: unites.length ? new Date(unites[0].date_debut) : params.get("arrivee") === null ? state.searchOptions.selectedArrivee || '' : parseDate(params.get("arrivee"), "yyyy-MM-dd", new Date()),
                    selectedDepart: unites.length ? new Date(unites[0].date_fin) : params.get("depart") === null ? state.searchOptions.selectedDepart || '' : parseDate(params.get("depart"), "yyyy-MM-dd", new Date()),
                    selectedNoConfirmation: params.get("noconfirmation") || "",
                    selectedNoGroupe: params.get("nogroupe") || "",
                    selectedIdEquipement: params.get("iduniteequipmenttype") || "",
                    selectedEquipementType: params.get("equipmenttype") || "",
                    selectedLongueur: params.get("longueurequipement") || "",
                    // selectedService: params.get("typeservice") || -1, // -1: tous les services
                    // selectedIdAmperage: params.get("idamperage") || "",
                    selectedAdultes: params.get("adultes") || 0,
                    selectedEnfants: params.get("enfants") || 0,
                    selectedTrancheAges: [],
                    extra: {},
                    selectedEntreeSortie: params.get("entreesortie") === "true",
                    selectedExtra: {}
                }
            }
        })
        getSearchFieldsExtra().then((searchFieldExtra) => {
            if (searchFieldExtra) searchFieldExtra.map((fieldExtra) => setState(prevState => {
                return {
                    ...prevState,
                    searchOptions: {
                        ...prevState.searchOptions,
                        extra: {
                            ...prevState.searchOptions.extra,
                            [fieldExtra.idParametresReservationEnLigne]: {
                                ...fieldExtra,
                                value: params.get("extravalue") || 0
                            }
                        },
                        selectedExtra: {
                            ...prevState.searchOptions.selectedExtra,
                            [fieldExtra.idParametresReservationEnLigne]: params.get("extravalue") || 0
                        }
                    }
                }
            }));
        });
    }

    const validerEtOuvrirPopupTrancheAge = async (idUnite) => {
        const unite = getUniteById(idUnite);

        const NbTrancheAgeAdulte = (unite.plan_base.TrancheAge || []).filter((trancheAge) => trancheAge.trancheAge.isAdulte).length;
        const NbTrancheAgeEnfants = (unite.plan_base.TrancheAge || []).filter((trancheAge) => !trancheAge.trancheAge.isAdulte).length;

        const {uniteAvecNbAdulteEtEnfant} = getUniteAvecTrancheAges(unite, true);

        setState(prevState => {
            return {
                ...prevState,
                AjouterPanierEtape: 1,
                searchOptions: {
                    ...prevState.searchOptions,
                    selectedTrancheAges: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
                }
            }
        });

        if ((unite.plan_base.TrancheAge || []).length > 0 && ((NbTrancheAgeAdulte > 1 && parseInt(state.searchOptions.selectedAdultes) > 0) || (NbTrancheAgeEnfants > 1 && parseInt(state.searchOptions.selectedEnfants) > 0))) {
            openTrancheAgePopup(uniteAvecNbAdulteEtEnfant);
            return true
        }
        return false;
    }

    const getUniteAvecTrancheAges = (unite, initialize = false) => {
        let nbAdultes = parseInt(state.searchOptions.selectedAdultes);
        let nbEnfants = parseInt(state.searchOptions.selectedEnfants);
        const uniteAvecNbAdulteEtEnfant = {
            ...unite,
            plan_base: {
                ...unite.plan_base,
                TrancheAge: state.searchOptions.selectedTrancheAges.length === 0 || initialize ? unite.plan_base.TrancheAge.map((tancheAge) => {
                    let tranche = {
                        ...tancheAge,
                        quantite: 0
                    };
                    if (tancheAge.trancheAge.isAdulte && nbAdultes !== 0) {
                        tranche = {
                            ...tancheAge,
                            quantite: nbAdultes
                        };
                        nbAdultes = 0;
                    } else if (!tancheAge.trancheAge.isAdulte && nbEnfants !== 0) {
                        tranche = {
                            ...tancheAge,
                            quantite: nbEnfants
                        };
                        nbEnfants = 0;
                    }
                    return tranche;
                }) : state.searchOptions.selectedTrancheAges
            }
        };

        return {
            nbAdultes,
            nbEnfants,
            uniteAvecNbAdulteEtEnfant
        };
    }

    const PrepareForAddToShoppingCart = async (unite) => {
        const idUnite = unite.id_unite;
        setUniteSelected(unite);
        if (state.AjouterPanierEtape < 1 && await validerEtOuvrirPopupTrancheAge(idUnite)) return;
        if (state.AjouterPanierEtape < 2 && await ouvrirPopupProduitOption(idUnite)) return;
        if (state.AjouterPanierEtape < 3 && await confirmationPopupMultipleUnite(idUnite)) return;

        //const unite = getUniteById(idUnite)
    }


    const formatDateYMD = (date) => {
        if (!(date instanceof Date)) return date;

        return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
    }


    const ouvrirPopupProduitOption = async (idUnite) => {
        const options = state.searchOptions;

        const dateArrivee = (options.selectedArrivee ? options.selectedArrivee : NULLDATE).setHours(23, 59, 59);
        const dateDepart = (options.selectedDepart ? options.selectedDepart : NULLDATE).setHours(23, 59, 59);

        const arrivee = toSelectedISODateTimeString(dateArrivee);
        const depart = toSelectedISODateTimeString(dateDepart);
        const optionsReservation = await getOptionsReservation(idUnite, arrivee, depart) || [];

        const OptionsReservation = await Promise.all(optionsReservation.filter((options) => options.typeAuxiliaire === TypeAuxiliaire.Produit).map(async (produitCourrant) => {
            const produit = await getProductById(produitCourrant.idAuxiliaire);

            return {
                ...produitCourrant,
                prixVente: produit.prixVente || 0,
                prixDetail: produit
            }
        }));
        if (OptionsReservation && OptionsReservation.length) {
            var products = [];
            const nbPersonne = parseInt(state.searchOptions.selectedEnfants) + parseInt(state.searchOptions.selectedAdultes);
            OptionsReservation.filter(p => p.ajoutAutomatique).forEach(async (cur) => {
                var doc = document.getElementById(cur.idAuxiliaire);
                const produit = await getProductById(cur.idAuxiliaire);

                let facteurModeCalcul = 1;
                switch (cur.modeCalcul) {
                    case OptionReservationModeCalcul.Duree:
                        facteurModeCalcul = differenceInDays(parseJSON(state.searchOptions.selectedDepart), parseJSON(state.searchOptions.selectedArrivee));
                        break;
                    case OptionReservationModeCalcul.DureePersonne:
                        facteurModeCalcul = differenceInDays(parseJSON(state.searchOptions.selectedDepart), parseJSON(state.searchOptions.selectedArrivee)) * nbPersonne;
                        break;
                    case OptionReservationModeCalcul.Global:
                        facteurModeCalcul = 1;
                        break;
                    case OptionReservationModeCalcul.Gratuite:
                        facteurModeCalcul = 0;
                        break;
                    default:
                        break;
                }
                products.push({
                    idProduit: cur.idAuxiliaire,
                    quantite: facteurModeCalcul,
                    description: produit.description,
                    prixVente: produit.prixVente
                });
            });
            const hasProduitNonAutomatique = OptionsReservation.some(p => !p.ajoutAutomatique);
            setState(prevState => {
                return {
                    ...prevState,
                    AjouterPanierEtape: 2,
                    selectedProducts: products,
                    produitsOptionnelsPopup: hasProduitNonAutomatique,
                    produits: OptionsReservation.filter((p) => p.ajoutAutomatique === false),
                    selectedIdUnite: idUnite,
                }
            });
            return hasProduitNonAutomatique;
        }
        return false;
    };

    const getUniteById = (id) => {
        return state.unites.find((unite) => unite.id_unite === id);
    }

    const openTrancheAgePopup = (selectedUnite) => {
        setState(prevState => {
            return {
                ...prevState,
                isTrancheAgePopupOpen: !state.isTrancheAgePopupOpen,
                selectedUnite: selectedUnite,
                selectedAdultesBackup: state.searchOptions.selectedAdultes,
                selectedEnfantsBackup: state.searchOptions.selectedEnfants,
                searchOptions: {
                    ...prevState.searchOptions,
                    selectedTrancheAges: selectedUnite.plan_base.TrancheAge
                }
            }
        });
    }

    const confirmationPopupMultipleUnite = async (idUnite) => {
        const OptionsReservation = await getOptionsReservation(idUnite);
        const unite = getUniteById(idUnite);
        const {uniteAvecNbAdulteEtEnfant} = getUniteAvecTrancheAges(unite);
        if (OptionsReservation && OptionsReservation.some((options) => options.typeAuxiliaire === TypeAuxiliaire.Unite)) {
            var unites = [];
            for (const optionIndex in OptionsReservation) {
                const option = OptionsReservation[optionIndex];
                const uniteAuxiliaire = state.unites.find((unite) => unite["id_unite"] === option.idAuxiliaire);
                if (!uniteAuxiliaire) continue;

                unites.push(uniteAuxiliaire["code_unite"]);
            }

            openConfirmationOptionReservationPopup(uniteAvecNbAdulteEtEnfant, `${ unites.length > 1 ? "Les unités" : "L'unité" } <b>${ unites.join(", ") }</b> ${ unites.length > 1 ? "seront" : " sera" } également ajouté au panier. Voulez-vous continuer?`);
            return true;
        }

        setState(prev => {
            return ({...prev, AjouterPanierEtape: 3});
        })

        return false;
    }

    const openConfirmationOptionReservationPopup = (selectedUnite, message) => {
        setState(prevState => {
            return {
                ...prevState,
                isOptionsReservaitonPopupOpen: true,
                selectedUnite: selectedUnite,
                OptionReservationMessage: message,
                AjouterPanierEtape: 3
            }
        });
    }


    const updateSearchField = (event) => {
        const option = event.target || event;
        var {
            name,
            value,
            checked,
            type
        } = option;

        updateOption(name.replace("selected", ""), option.selectedIndex ? option[option.selectedIndex].innerText : type === "checkbox" ? checked : value, value);

        setState(prevState => {
            return {
                ...prevState,
                dataLoadedUnites: false,
                isCalenderDateChange: false,
                isSearchOption: true,
                searchOptions: {
                    ...prevState.searchOptions,
                    [name]: type === "checkbox" ? checked : value
                }
            }
        });
    }

    const updateInputSearchField = (event) => {
        const option = event.target || event;
        var {
            name,
            value,
            checked,
            type
        } = option;
        setState(prevState => {
            return {
                ...prevState,
                searchOptions: {
                    ...prevState.searchOptions,
                    [name]: value
                }
            }
        });
    }

    const updateOption = (name, textVal, value) => {
        const {includedOptions} = state;
        const newOptionValue = {
            name: name,
            textVal: textVal,
            value: value
        };
        let options = null;
        const index = includedOptions.findIndex((o) => name === o.name);

        if (index >= 0) {
            includedOptions[index].value = value;
            includedOptions[index].textVal = textVal;
        } else {
            options = [...includedOptions, newOptionValue];
        }

        setState(prevState => {
            return {
                ...prevState,
                includedOptions: [...(options || includedOptions)]
            }
        });
    }

    const resetSearchOptions = (keepDate) => {
        let searchOptions = {
            selectedIdEquipement: "",
            selectedLongueur: "",
            // selectedService: -1, // -1: tous les services
            // selectedIdAmperage: "",
            selectedAdultes: 0,
            selectedEnfants: 0,
            selectedEntreeSortie: false
        };

        let dateSearchOptions = keepDate ? {} : {
            selectedArrivee: null,
            selectedDepart: null
        };

        setState(prevState => {
            return {
                ...prevState,
                isCalenderDateChange: false,
                isSearchOption: true,
                searchOptions: {...prevState.searchOptions, ...searchOptions, ...dateSearchOptions},
                includedOptions: [],
                previewArrivee: null,
                previewDepart: null,
                fieldError: {},
                selectedIdUnite: null,
                selectedProducts: null,
                AjouterPanierEtape: 0
            }
        });
    }

    const getAllUnites = () => {
        setState(prevState => {
            return {
                ...prevState,
                dataLoadedUnites: false,
                unites: []
            }
        });

        // const idTypeUnite = new URLSearchParams(props.location.search).get("idTypeUnite");
        // return getUnites(idTypeUnite, this.state.typeHebergement, this.generateQueryStringInclude()).then((unites) => {
        //   //console.log(`${unites.length} unité(s) chargés. Premier possible unité à la prochaine ligne.`);
        //   //console.log(unites.length ? unites[0] : {});

        //   this.setState({
        //     dataLoadedUnites: true,
        //     unites: unites
        //   });
        // });
    }

    const validateErrors = (idUnite) => {
        const errors = {}
        if (!state.searchOptions.selectedNoConfirmation) errors['noConfirm'] = 'Veuillez entrer un numéro de confirmation';
        if (!state.searchOptions.selectedNoGroupe) errors['noGroup'] = 'Veuillez entrer un numéro de groupe';
        // if (!state.searchOptions.selectedAdultes && !state.searchOptions.selectedEnfants) errors['selectedPersons'] = "Veuillez entrer un nombre d'adulte ou d'enfant";
        if (Object.keys(errors).length) {
            setState(prevState => {
                return {
                    ...prevState,
                    fieldError: errors
                }
            })
            return false;
        }
        return true;
    }

    const onTrancheAgeValueChange = (trancheAges) => {
        if (trancheAges.length > 0) {
            let nbAdultes = 0;
            let nbEnfants = 0;
            trancheAges.forEach((trancheage) => {
                if (trancheage.trancheAge.isAdulte) nbAdultes += parseInt(trancheage.quantite); else nbEnfants += parseInt(trancheage.quantite);
            });

            setState(prevState => {
                return {
                    ...prevState,
                    searchOptions: {
                        ...prevState.searchOptions,
                        selectedAdultes: nbAdultes,
                        selectedEnfants: nbEnfants,
                        selectedTrancheAges: trancheAges
                    }
                }
            });
        }
    }

    // const onConfirmationOptionReservationPopup = (selectedUnite) => {
    //   const { uniteAvecNbAdulteEtEnfant } = getUniteAvecTrancheAges(selectedUnite);
    //   setState({
    //     ...state,
    //     OptionsReservaitonPopupResult: true,
    //     isOptionsReservaitonPopupOpen: false,
    //     AjouterPanierEtape: 3,
    //   }, () => {

    //     setState({
    //       ...state,
    //       OptionsReservaitonPopupResult: false,
    //       searchOptions: {
    //         ...state.searchOptions,
    //         selectedTrancheAges: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
    //       }
    //     }, async () => {
    //       const idReservationTempParent = await addToShoppingCart(uniteAvecNbAdulteEtEnfant, false);
    //       let idReservationTemp;
    //       const OptionsReservation = await getOptionsReservation(uniteAvecNbAdulteEtEnfant.id_unite);
    //       if (OptionsReservation && OptionsReservation.some((options) => options.typeAuxiliaire === TypeAuxiliaire.Unite)) {
    //         for (const optionIndex in OptionsReservation) {
    //           const option = OptionsReservation[optionIndex];
    //           const uniteAuxiliaire = state.unites.find((unite) => unite["id_unite"] === option.idAuxiliaire);
    //           if (!uniteAuxiliaire) continue;

    //           const uniteOptionReservation = {
    //             ...uniteAuxiliaire,
    //             idReservationTempParent: idReservationTempParent,
    //             plan_base: {
    //               ...uniteAuxiliaire.plan_base,
    //               TrancheAge: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
    //             }
    //           };

    //           idReservationTemp = await addToShoppingCart(uniteOptionReservation, false);
    //         }
    //       }
    //       if (idReservationTemp || idReservationTempParent) resetSearchOptions(true);
    //     });
    //   });
    // }

    const onConfirmationOptionReservationPopup = async (selectedUnite) => {
        const {uniteAvecNbAdulteEtEnfant} = getUniteAvecTrancheAges(selectedUnite);
        // setState({
        //   ...state,
        //   OptionsReservaitonPopupResult: true,
        //   isOptionsReservaitonPopupOpen: false,
        //   AjouterPanierEtape: 3,
        // },
        //   () => {

        setState(prevState => {
            return {
                ...prevState,
                OptionsReservaitonPopupResult: true,
                isOptionsReservaitonPopupOpen: false,
                searchOptions: {
                    ...prevState.searchOptions,
                    selectedTrancheAges: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
                },
                AjouterPanierEtape: 3,
            }
        });
        const idReservationTempParent = await addToShoppingCart(uniteAvecNbAdulteEtEnfant, false);
        let idReservationTemp;
        const OptionsReservation = await getOptionsReservation(uniteAvecNbAdulteEtEnfant.id_unite);
        if (OptionsReservation && OptionsReservation.some((options) => options.typeAuxiliaire === TypeAuxiliaire.Unite)) {
            for (const optionIndex in OptionsReservation) {
                const option = OptionsReservation[optionIndex];
                const uniteAuxiliaire = state.unites.find((unite) => unite["id_unite"] === option.idAuxiliaire);
                if (!uniteAuxiliaire) continue;

                const uniteOptionReservation = {
                    ...uniteAuxiliaire,
                    idReservationTempParent: idReservationTempParent,
                    plan_base: {
                        ...uniteAuxiliaire.plan_base,
                        TrancheAge: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
                    }
                };

                idReservationTemp = await addToShoppingCart(uniteOptionReservation, false);
            }
        }
        if (idReservationTemp || idReservationTempParent) resetSearchOptions(true);
        // }
        // );
    }

    const addToShoppingCart = async (unite, resertSearch = true) => {
        const newItem = await valideEtPrepareData(unite);
        if (newItem === false) {
            setState(prevState => {
                return {
                    ...prevState,
                    AjouterPanierEtape: 0
                }
            });
            return;
        }

        var montants = await calculateSumarryAmounts(newItem["selectedUnite"]);
        if (montants.montantDetailles.some(mntDetail => mntDetail.description === "Invalide")) {
            props.setErrorAlert("Une erreur s'est produite lors de l'ajout au panier");
            return;
        }
        const confirmation = state.searchOptions.selectedNoConfirmation;
        const result = await addReservationTemp({
            dateDebut: newItem["selectedUnite"].selectedArrivee,
            dateFin: newItem["selectedUnite"].selectedDepart,
            idUnite: unite.id_unite,
            idReservationDetail: unite.id_reservation_detail
        });

        if (result.uniteIsUnavailable) {
            props.setErrorAlert("L'unité n'est actuellement pas disponible pour la période sélectionnée. Veuillez modifier et réessayer!");
            return;
        }

        newItem["idsReservationTemp"] = result.idsReservationTemp;

        props.addToShoppingCart(newItem);
        props.setTimeoutShop(result.idsReservationTemp);
        setState(prevState => {
            return {
                ...prevState,
                fieldError: {}
            }
        });
        if (resertSearch) resetSearchOptions(true);
        updateOption("Arrivée", toDateString(newItem["selectedUnite"].selectedArrivee), toSelectedISODateTimeString(newItem["selectedUnite"].selectedArrivee));
        updateOption("Départ", toDateString(newItem["selectedUnite"].selectedArrivee), toSelectedISODateTimeString(newItem["selectedUnite"].selectedArrivee));
        return result.idsReservationTemp;

    }

    const valideEtPrepareData = async (unite) => {
        const idUnite = unite.id_unite;
        const newItem = {};

        newItem["image"] = await FetchApi("/" + props.idZec + "/Unite/" + idUnite + "/Image").then((image) => (image === null ? require("../../../images/no-image.png") : "data:image/jpeg;base64," + image));
        const options = state.searchOptions;

        const dateArrivee = (options.selectedArrivee ? options.selectedArrivee : NULLDATE).setHours(new Date(unite.heure_arrivee).getHours(), new Date(unite.heure_arrivee).getMinutes());
        const dateDepart = (options.selectedDepart ? options.selectedDepart : NULLDATE).setHours(new Date(unite.heure_depart).getHours(), new Date(unite.heure_depart).getMinutes());

        const arrivee = toSelectedISODateTimeString(dateArrivee);
        const depart = toSelectedISODateTimeString(dateDepart);

        if (depart < arrivee) {
            props.setErrorAlert("La date de départ pour cet unite doit être après de la date d'arrivée!");
            setState(prevState => {
                return {
                    ...prevState,
                    fieldError: {selectedDate: "la date de départ invalide !"}
                }
            });
            return false;
        }
        if (!options.selectedArrivee) {
            props.setErrorAlert("La date d'arrivée est invalide!");
            setState(prevState => {
                return {
                    ...prevState,
                    fieldError: {selectedDate: "La date d'e 'arrivée est invalide !"}
                }
            });
            return false;
        }
        if (!options.selectedArrivee) {
            props.setErrorAlert("La date de départ est invalide");
            setState(prevState => {
                return {
                    ...prevState,
                    fieldError: {selectedDate: "La date de départ est invalide !"}
                }
            });
            return false;
        }

        const equipemenType = null;
        // const equipemenType = (state.searchFields.equipements || []).find((equipement) => {
        //   return equipement.id_unite_equipment_type === options.selectedIdEquipement;
        // });

        var includedOptions = [{
            name: "Arrivée",
            textVal: formatDate(dateArrivee, unite.cacher_heures ? "yyyy-MM-dd " : "yyyy-MM-dd HH:mm:ss"),
            value: arrivee
        }, {
            name: "Départ",
            textVal: formatDate(dateDepart, unite.cacher_heures ? "yyyy-MM-dd " : "yyyy-MM-dd HH:mm:ss"),
            value: depart
        }, {
            name: "Adultes",
            textVal: `${ options.selectedAdultes }`,
            value: options.selectedAdultes
        }, {
            name: "Enfants",
            textVal: `${ options.selectedEnfants }`,
            value: options.selectedEnfants
        }]; //this.state;
        if (equipemenType && equipemenType.description) includedOptions = [...includedOptions, {
            name: "Équipement",
            textVal: equipemenType.description,
            value: options.selectedIdEquipement
        }];

        if (options.selectedLongueur !== "") includedOptions = [...includedOptions, {
            name: "Longueur",
            textVal: `${ options.selectedLongueur }`,
            value: options.selectedLongueur
        }];

        if (unite.services && unite.services.length > 0) includedOptions = [...includedOptions, {
            name: "Service",
            textVal: unite.services.map((service) => service.description).join(", "),
            value: unite.services
        }];

        newItem["selectedUnite"] = {...unite, ...options};
        newItem["selectedUnite"].selectedArrivee = arrivee;
        newItem["selectedUnite"].selectedDepart = depart;
        newItem["name"] = "Unité-" + unite.description;
        newItem["type"] = TypeHebergement[unite.type_hebergement] || TypeHebergement.CH;
        newItem["isReservation"] = true;
        newItem["inclus"] = includedOptions;
        newItem["selectedUnite"]["selectedTrancheAges"] = options.selectedTrancheAges.map((trancheAge) => {
            return {
                IdTrancheAge: trancheAge.trancheAge.idTrancheAge,
                quantite: trancheAge.quantite
            };
        });
        const selectedProducts = state.selectedProducts || [];
        for (let i = 0; i < selectedProducts.length; i++) {
            const montantProduit = selectedProducts[i].prixVente * selectedProducts[i].quantite;
            newItem["inclus"].push({
                name: `${ selectedProducts[i].description }:   x ${ selectedProducts[i].quantite }${ selectedProducts[i].quantiteTypeHoraire ? "h" : "" }`,
                montant: montantProduit
            });
        }
        newItem["selectedUnite"]["SelectedProducts"] = selectedProducts;
        const errors = await validateSearchOptions(JSON.stringify(newItem["selectedUnite"]));

        if (Object.keys(errors).length > 0) {
            // Ajouter l'unité aux reservations temporaires
            setState(prevState => {
                return {
                    ...prevState,
                    fieldError: errors
                }
            });
            props.setErrorAlert({
                description: "Il y a des erreurs dans les options de recherche. Veuillez corriger et reessayer!",
                details: Object.values(errors)
            });
            return false;
        }

        return newItem;
    }

    const getInputClassName = () => {
        return state.fieldError !== null && state.fieldError["selectedDate"] ? `choix-unite-recherche-input-wrapper ${ mandatoryInputError }` : "choix-unite-recherche-input-wrapper";
    };


    const applySearchFilters = async (validate = true) => {
        setState(prevState => {
            return {
                ...prevState,
                dataLoadedUnites: false,
                unites: []
            }
        });

        if (validate) {
            const isValid = validateErrors();
            if (!isValid) return;
        } else return;

        //115 et 997D2A30-62E4-E811-BE4B-70C94E872D18 ou BD98C617-62E4-E811-BE4B-70C94E872D18
        let baseUrl = `?NoConfirmation=${ state.searchOptions.selectedNoConfirmation }&NoGroupe=${ state.searchOptions.selectedNoGroupe }`;

        if (state.searchOptions.selectedIdEquipement) {
            baseUrl += `&IdUniteEquipmentType=${ state.searchOptions.selectedIdEquipement }`
        }
        if (state.searchOptions.selectedLongueur) {
            baseUrl += `&LongueurEquipement=${ state.searchOptions.selectedLongueur }`
        }

        var unites = await getGroupSearch(`Unite/groupSearch${ baseUrl }`);

        props.history.push({search: baseUrl})

        setState(prevState => {
            return {
                ...prevState,
                dataLoadedUnites: true,
                searchOptions: {
                    ...prevState.searchOptions,
                    selectedArrivee: unites[0] ? new Date(unites[0].date_debut) : null,
                    selectedDepart: unites[0] ? new Date(unites[0].date_fin) : null,
                },
                unites: unites
            }
        });
    }

    const fetchUnites = async (params) => {
        let baseUrl = `?NoConfirmation=${ params.get("noconfirmation") }&NoGroupe=${ params.get("nogroupe") }`;
        if (params.get("iduniteequipmenttype")) baseUrl += `&IdUniteEquipmentType=${ params.get("iduniteequipmenttype") }`
        if (params.get("longueurequipement")) baseUrl += `&LongueurEquipement=${ params.get("longueurequipement") }`
        return await getGroupSearch(`Unite/groupSearch${ baseUrl }`);
    }

    const hasError = state.fieldError !== null;
    // const getSelectClassName = (key) => (props.fieldError !== null && props.fieldError[key] ? `${fieldClassName} ${mandatoryInputError}` : fieldClassName);
    return (
        <div id="choix-unite-page">
            { state.produitsOptionnelsPopup && (
                <CatalogueProduitsPopup
                    //isOpen={state.produitsOptionnelsPopup}
                    open
                    handleClose={ () => setState(prevState => {
                        return {
                            ...prevState,
                            produitsOptionnelsPopup: false
                        }
                    }) }
                    produits={ state.produits }
                    addToShoppingCartSuite={ async (idUnite, produits) => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                AjouterPanierEtape: 2,
                                selectedProducts: [...state.selectedProducts, ...(produits || [])]
                            }
                        })

                    } }
                    arrivee={ state.searchOptions.selectedArrivee }
                    depart={ state.searchOptions.selectedDepart }
                    idUnite={ state.selectedIdUnite }
                    nombrePersonne={ parseInt(state.searchOptions.selectedAdultes) + parseInt(state.searchOptions.selectedEnfants) }
                />) }
            { state.isTrancheAgePopupOpen && (
                <DemanderTrancheAge
                    unite={ state.selectedUnite }
                    trancheAges={ state.searchOptions.selectedTrancheAges || state.selectedUnite.plan_base.TrancheAge }
                    isOpen={ state.isTrancheAgePopupOpen }
                    onValueChange={ onTrancheAgeValueChange }
                    onConfirmation={ async (idUnite) => {
                        const unite = uniteSelected || await getUniteById(idUnite);
                        PrepareForAddToShoppingCart(unite)
                    } }
                    handleClose={ (isCancel) => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                isTrancheAgePopupOpen: false,
                                selectedUnite: null,
                                searchOptions: isCancel ? {
                                    ...prevState.searchOptions,
                                    selectedAdultes: prevState.selectedAdultesBackup,
                                    selectedEnfants: prevState.selectedEnfantsBackup
                                } : {...prevState.searchOptions},
                                AjouterPanierEtape: (isCancel ? 0 : prevState.AjouterPanierEtape)
                            }
                        });
                    } }
                />) }
            { state.isOptionsReservaitonPopupOpen && (
                <ConfirmationOptionReservation
                    message={ state.OptionReservationMessage }
                    selectedUnite={ state.selectedUnite }
                    isOpen={ state.isOptionsReservaitonPopupOpen }
                    handleClose={ () => setState(prevState => {
                        return {
                            ...prevState,
                            isOptionsReservaitonPopupOpen: false,
                            selectedUnite: null,
                            AjouterPanierEtape: 0
                        }
                    }) }
                    onConfirmation={ onConfirmationOptionReservationPopup }
                />) }

            {/* {this.props.showRecherche && (
        <ChoixRecherchePanel
          affichageClass={"type-affichage-carte"}
          hasError={hasError}
          fieldError={state.fieldError}
          getInputClassName={this.getInputClassName}
          toggleCalendrierArrivee={this.toggleCalendrierArrivee}
          toggleCalendrierDepart={this.toggleCalendrierDepart}
          formatDateYMD={this.formatDateYMD}
          previewArrivee={this.state.previewArrivee}
          previewDepart={this.state.previewDepart}
          searchOptions={this.state.searchOptions}
          showCalendrierArrivee={this.state.showCalendrierArrivee}
          showCalendrierDepart={this.state.showCalendrierDepart}
          blurCalendrierArrivee={this.blurCalendrierArrivee}
          blurCalendrierDepart={this.blurCalendrierDepart}
          maxJoursEnAvanceReservation={parseInt(this.state.maxJoursEnAvanceReservation || 0)}
          onDayClick={this.onSelectDateArrivee}
          onHoveredDayChange={this.onDateArriveeHoverChange}
          selectedRangeBegin={this.state.searchOptions.selectedArrivee}
          selectedRangeEnd={this.state.searchOptions.selectedDepart}
          initialMonth={this.state.searchOptions.selectedArrivee || this.state.searchOptions.selectedDepart}
          isSelectingRangeBegin={true}
          minDate={today}
          onDayClickDepart={this.onSelectDateDepart}
          onHoveredDayChangeDepart={this.onDateDepartHoverChange}
          initialMonthDepart={this.state.searchOptions.selectedDepart || this.state.searchOptions.selectedArrivee}
          isSelectingRangeEnd={true}
          idUnite={idUnite}
          isTypeAffichageUnite={isTypeAffichageUnite}
          idTypeUnite={idTypeUnite}
          typeHebergement={this.state.typeHebergement}
          applySearchFilters={this.applySearchFilters}
          addUniteToShoppingCart={this.PrepareForAddToShoppingCart}
          showAddToShoppingCart={!this.state.demandeDeReservationParCourriel}
          resetSearchOptions={this.resetSearchOptions}
          updateSearchField={this.updateSearchField}
          updateSearchFieldExtra={this.updateSearchFieldExtra}
          consultationSeulement={this.state.consultationSeulement}
          peutAjouterPanier={this.state.peutAjouterPanier}
          language={this.props.match.params.language}
        />)} */ }

            <div id="choix-unite-recherche" className={ "type-affichage-carte" }>
                <div>
                    <div className="choix-unite-recherche-section">
                        <div className="choix-unite-recherche-title-wrapper">
                            { hasError &&
                                <ChampDeRechercheFieldError errorMessage={ state.fieldError?.noConfirm }/> }
                            <span>NO. CONFIRMATION</span>
                        </div>
                        <div className="choix-unite-recherche-input-wrapper">
                            <input className="selectedIdEquipement"
                                   name="selectedNoConfirmation"
                                   value={ state.searchOptions.selectedNoConfirmation }
                                   onChange={ updateSearchField }
                            />
                        </div>
                    </div>

                    <div className="choix-unite-recherche-section">
                        <div className="choix-unite-recherche-title-wrapper">
                            { hasError &&
                                <ChampDeRechercheFieldError errorMessage={ state.fieldError?.noGroup }/> }
                            <span>NO. GROUPE</span>
                        </div>
                        <div className="choix-unite-recherche-input-wrapper">
                            <input className="selectedIdEquipement"
                                   name="selectedNoGroupe"
                                   value={ state.searchOptions.selectedNoGroupe }
                                   onChange={ updateSearchField }
                            />
                        </div>
                    </div>

                    <div className="choix-unite-recherche-section">
                        <div className="choix-unite-recherche-title-wrapper">
                            <span>{ TEXTS.title5 }</span>
                        </div>
                        <div className="choix-unite-recherche-input-wrapper">
                            <select className="selectedIdEquipement"
                                    name="selectedIdEquipement"
                                // value={state.searchOptions.selectedIdEquipement}
                                // onChange={(e) => setSelectedEquipement(e.target.value)}
                                    value={ state.searchOptions.selectedIdEquipement }
                                    onChange={ updateSearchField }
                            >
                                { searchField.equipements.length > 1 && (
                                    <option key="nothing-selected" value="">
                                        { TEXTS.title6 }
                                    </option>
                                ) }
                                { searchField.equipements.map((equipement) => (
                                    <option key={ equipement.id_unite_equipment_type }
                                            value={ equipement.id_unite_equipment_type }>
                                        { equipement.description.toUpperCase() }
                                    </option>
                                )) }
                            </select>
                        </div>
                    </div>

                    <div className="choix-unite-recherche-section">
                        <div className="choix-unite-recherche-title-wrapper">
                            <span>{ TEXTS.title7 }</span>
                        </div>
                        <div className="choix-unite-recherche-input-wrapper">
                            <select className={ "selectedLongueur" } name="selectedLongueur"
                                    value={ state.searchOptions.selectedLongueur }
                                    onChange={ updateSearchField }
                            >
                                <option key="nothing-selected" value="">
                                    { TEXTS.title8 }
                                </option>
                                { searchField.longueursEquipements.map((longueur) => (
                                    <option key={ longueur.longueur } value={ longueur.longueur }>
                                        { longueur.description.toUpperCase() }
                                    </option>
                                )) }
                            </select>
                        </div>
                    </div>

                    {
                        state.unites.length &&
                        <>
                            <div className="choix-unite-recherche-section date">
                                <div className="choix-unite-recherche-title-wrapper date">
                                    { hasError &&
                                        <ChampDeRechercheFieldError errorMessage={ state.fieldError.selectedDate }/> }
                                    <span>SÉJOUR</span>
                                </div>
                                <div className={ getInputClassName() }>
                                    <div className="choix-unite-recherche-demi-input-wrapper">
                                        <input type="text" placeholder='ARRIVÉE' className="clickable"
                                            // onMouseDown={(e) => props.toggleCalendrierArrivee()}
                                               value={ formatDateYMD(state.previewArrivee || state.searchOptions.selectedArrivee) || "" } readOnly
                                        />
                                        <img name="image" className="calendar-icon clickable" src={ require("../../../images/icons/calendar-2.png") } alt="calendrier"
                                            // onMouseDown={() => props.toggleCalendrierArrivee()}
                                        />
                                        { state.showCalendrierArrivee && (
                                            <div
                                                className="calendar-wrapper"
                                                // ref="calendarArrivalWrapperDiv"
                                                tabIndex={ 0 }
                                                onBlur={ (e) => props.blurCalendrierArrivee() }
                                            >
                                                {/* <Calendar
                    maxJoursEnAvanceReservation={props.maxJoursEnAvanceReservation}
                    onDayClick={props.onDayClick}
                    onHoveredDayChange={props.onHoveredDayChange}
                    selectedRangeBegin={props.selectedRangeBegin}
                    selectedRangeEnd={props.selectedRangeEnd}
                    initialMonth={props.initialMonth}
                    isSelectingRangeBegin={props.isSelectingRangeBegin}
                    minDate={props.minDate}
                    language={props.language}
                  /> */ }
                                            </div>
                                        ) }
                                    </div>
                                    <div className="choix-unite-recherche-demi-input-wrapper">
                                        <input type="text" placeholder='DÉPART' className="clickable"
                                            // onMouseDown={(e) => props.toggleCalendrierDepart()}
                                               value={ formatDateYMD(state.previewDepart || state.searchOptions.selectedDepart) || "" } readOnly
                                        />
                                        <img className="calendar-icon clickable" src={ require("../../../images/icons/calendar-2.png") } alt="calendrier"
                                            // onMouseDown={(e) => props.toggleCalendrierDepart()}
                                        />
                                        { state.showCalendrierDepart && (
                                            <div
                                                className="calendar-wrapper"
                                                // ref="calendarDepartureWrapperDiv"
                                                tabIndex={ 0 }
                                                onBlur={ (e) => props.blurCalendrierDepart() }
                                            >
                                                {/* <Calendar
                    maxJoursEnAvanceReservation={props.maxJoursEnAvanceReservation}
                    onDayClick={props.onDayClickDepart}
                    onHoveredDayChange={props.onHoveredDayChangeDepart}
                    selectedRangeBegin={props.selectedRangeBegin}
                    selectedRangeEnd={props.selectedRangeEnd}
                    initialMonth={props.initialMonthDepart}
                    isSelectingRangeEnd={props.isSelectingRangeEnd}
                    minDate={props.minDate}
                    language={props.language}
                  /> */ }
                                            </div>
                                        ) }
                                    </div>
                                </div>
                            </div>
                            <div className="choix-unite-recherche-section">
                                <div className="choix-unite-recherche-title-wrapper">{ hasError &&
                                    <ChampDeRechercheFieldError errorMessage={ state.fieldError?.selectedAdultes || state.fieldError?.selectedEnfants }/> }
                                </div>
                                <div className="choix-unite-recherche-input-wrapper">
                                    <div className="choix-unite-recherche-demi-input-wrapper">
                                        <div className="choix-unite-recherche-title-wrapper">
                                            <span>{ TEXTS.title0 }</span>
                                        </div>
                                        <SpinInput name="selectedAdultes" value={ state.searchOptions.selectedAdultes || 0 } max={ 999 } min={ 0 }
                                                   onChange={ updateSearchField }
                                        />
                                    </div>
                                    <div className="choix-unite-recherche-demi-input-wrapper">
                                        <div className="choix-unite-recherche-title-wrapper">
                                            <span>{ TEXTS.title1 }</span>
                                        </div>
                                        <SpinInput name="selectedEnfants" value={ state.searchOptions.selectedEnfants || 0 } max={ 999 } min={ 0 }
                                                   onChange={ updateSearchField }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="choix-unite-recherche-section">
                                <div className="choix-unite-recherche-title-wrapper">{ hasError &&
                                    <ChampDeRechercheFieldError errorMessage={ state.fieldError.selectedEntreeSortie }/> }</div>
                                <div className="choix-unite-recherche-input-wrapper">
                                    <div className="choix-unite-recherche-title-wrapper">
                                        <span>TERRAIN AVEC ENTRÉE ET SORTIE</span>
                                    </div>
                                    <input type="checkbox" className={ "entreeSortieCheckbox" } name="selectedEntreeSortie" checked={ state.searchOptions.selectedEntreeSortie || false } value={ state.searchOptions.selectedEntreeSortie || false } onChange={ updateSearchField }/>
                                </div>
                            </div>
                        </>

                    }
                </div>

                <div className="choix-unite-recherche-section" style={ {marginTop: "32px"} }>
                    <div className="choix-unite-recherche-button-wrapper"
                         onClick={ applySearchFilters }
                    >
                        <span> { TEXTS.title3 } </span>
                    </div>
                </div>
            </div>

            <div id="choix-unite-main-content" className={ "type-affichage-liste" }>
                <div className={ "choix-unite-affichage no-recherche" }>
                    {
                        state.unites && state.unites.length > 0 ?
                            <ChoixUniteListe
                                isActivite={ false }
                                showCalendrier={ false }
                                showIcons={ false }
                                showRecherche={ false }
                                typeHebergement={ state.typeHebergement }
                                unites={ state.unites }
                                onClickAjouterPanier={ PrepareForAddToShoppingCart }
                                showRechercheConsulter={ false }
                                showAddToShoppingCart={ !state.demandeDeReservationParCourriel && !state.consultationSeulement }
                                setIsSearchShown={ false }
                                className=" has-recherche "
                            />
                            :
                            <div id="aucune-unite-wrapper">{ (!state.dataLoadedUnites &&
                                    <img src={ require("../../../images/loading.svg") } alt="chargement"/>) ||
                                state.unites != null &&
                                <span>AUCUNE UNITÉ NE CORRESPOND AUX CRITÈRES DE RECHERCHE.</span> }
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

// TerrainPage.defaultProps = {
//   isModifying: false
// };
const
    dispatchProps = {
        addToShoppingCart,
        setErrorAlert,
        setTimeoutShop
    };
export default withNavigation(connect(null, dispatchProps)(TerrainPage));