import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect, useParams} from "react-router-dom";
import {connect} from "react-redux";
import ReservationPage from './components/reservation-page.js';
import AnnulationReservationPage from './components/reservation/annuler-reservation.js';
import AchatForfaitPage from './components/achat-forfait/achat-forfait-page.js';
import TiragePage from './components/tirage-page.js';
import DroitAccesPage from './components/droit-acces-page.js';
import BilletUnite from './components/billetterie/billet-unite.js';
import BoutiquePage from './components/boutique/boutique-page.js';
import CachePage from './components/cache/cache-page.js';
import PanierPage from './components/panier/panier-page.js';
import RestaurantPage from './components/restaurant/restaurant-page.js';
import RecherchePage from './components/recherche/recherche-page.js';
import BilletteriePage from './components/billetterie/billetterie-page.js';
import PaiementComptesPage from './components/paiement-comptes/paiement-comptes-page.js';
import ChoixUnitePage from './components/choix-unite-page.js';
import ChoixUniteCalendrier from './components/choix-unite-calendrier.js';
import LecteurCartesMembresPage from './components/lecteur-cartes-membres-page.js';
import ProfilePage from './components/profile-page.js';
import PaiementPage from './components/paiement/paiement-page.js';
import TerrainPage from './components/reservation/reservation-terrain';
import KiosquePage from './components/kiosque/kiosque-page.js';
import Header from './components/header.js';
import CampsPage from './components/camps2/camps-page.js';
import Footer from './components/footer.js';
import MainBanner from './components/main-banner.js';
import MainMenu from './components/main-menu.js';
import Initializer from './components/initializer.js';
import Alert from './components/popups/Alert.js';
import PageNotFound from "./components/page-not-found";
import '../css/app.css';
import '../css/droit-acces-page.css';
import ActivityReservationPage from './components/reservation/activity-reservation-page.js';
import LoadingOverlay from 'react-loading-overlay';
import GenericPopup from './components/popups/generic.js';
import SessionKeys from "./session.js";
import {setAuthToken} from "./components/actions/auth-token";
import {SaveProfilClient} from './components/actions/infoClient'
import {setNoDossier} from "./components/actions/client";
import {initializeShoppingCart, initialzePromotion} from "./components/actions/shop";
import * as navigation from "./constants/navigation-constants";
import {STORAGE} from './constants/storage.js'
import {installGtag} from "./utils/gtag";
import {setFromStorage, getFromStorage} from './utils/localStorageUtils'
import {getConfigurationByKeyName} from "./server/services/configuration-service";
import RenderActivite from './components/activites/render-activites.js'
import FermerEnregistrement from './components/fermer-enregistrement/connecte/fermer.js';
import FermerEnregistrementEtCapture from './components/fermer-enregistrement/connecte/fermer-capture.js';
import VerifieAuthentification from './components/fermer-enregistrement/verifie-authentification.js';
import Evenement from './components/evenements/evenement.js';
import BookingPage from './components/reservation/reservation-liste.js';


LoadingOverlay.propTypes = undefined

function ScrollToTop() {
    //console.log(useLocation());
    //console.log(useHistory());

    //const { pathname } = useLocation();
    //const scrollTo = document.getElementById('main-menu') === null || pathname === "/" ? 0
    //               : pathname === "/choix-unite/liste" || pathname === "/choix-unite/carte" ? null
    //               : document.getElementById('main-menu').offsetTop;
    //
    //useEffect(() => {
    //    if (scrollTo != null)
    //        window.scrollTo(0, scrollTo);
    //}, [pathname]);
    //
    //return null;

    return null;
}

function mapStateToProps(state, ownProps) {
    return {
        overlay: state.overlay,
    }
}

function HomePageRedirection() {
    let {idZec, language} = useParams();
    return <Redirect to={ `/${ idZec }/${ language || "fr" }${ navigation.PATH_ACCUEIL }` }/>
}

const PARAM_IDZEC_LANGUE = "/:idZec/:language";
const PARAM_IDZEC = "/:idZec";
const PARAM_TYPEUNITE_IDUNITE = "/:idTypeUnite/:idUnite";
const PARAM_IDBILLET = "/:idBillet";

function App(props) {
    const [isStorageRetrieved] = useState(retrieveStorageData);
    useEffect(() => {
        getConfigurationByKeyName("GTAG_ID")
            .then((config) => {
                    const gtagId = config?.keyValue || '';
                    if (gtagId && gtagId !== "")
                        installGtag(gtagId, {'send_page_view': false})
                }
            );
    }, [])

    function retrieveStorageData() {
        if (sessionStorage.getItem(SessionKeys.AUTH_TOKEN) !== null)
            props.setAuthToken(sessionStorage.getItem(SessionKeys.AUTH_TOKEN));

        const cartItem = sessionStorage.getItem('cart');
        const promotion = sessionStorage.getItem('promotions');
        const cart = {
            cart: JSON.parse(cartItem),
            promotions: JSON.parse(promotion)
        };

        props.initializeShoppingCart(cart);

        if (sessionStorage.getItem(SessionKeys.NO_DOSSIER) !== null)
            props.setNoDossier(sessionStorage.getItem(SessionKeys.NO_DOSSIER), true);

        if (getFromStorage(STORAGE.info_profile_client)) {
            let dataProlieClient = getFromStorage(STORAGE.info_profile_client)
            if (dataProlieClient) props.SaveProfilClient(dataProlieClient) // sauvegarde dans Redux
        }

        return true;
    }

    let overlay = null;
    for (let key in props.overlay.showByKey) {
        overlay = props.overlay.showByKey[key];
        break;
    }
    if (!isStorageRetrieved) {
        return <div/>
    }


    return (
        <div id="app">
            <Router>
                <Switch>
                    <Route path={ [PARAM_IDZEC_LANGUE, PARAM_IDZEC] }> {/*Nécessaire pour pouvoir lire this.props.match.params.idZec depuis le Header (une component à l'extérieur d'une Route n'a pas accès aux this.props.match.params comprient dans cette route. Header doit donc être à l'intérieur d'une route qui utilise le idZec) */ }
                        <Initializer>
                            <LoadingOverlay active={ overlay !== null } spinner text={ (overlay || {}).text }>
                                <GenericPopup>
                                    <Alert/>
                                    <ScrollToTop/>
                                    <Header/>
                                    <MainBanner/>
                                    <div id="app-content">
                                        <MainMenu/>
                                        <div id="main-content-wrapper">
                                            <Switch>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_BOUTIQUE }` }>
                                                    <BoutiquePage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_PANIER }` }>
                                                    <PanierPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_RESTAURANT }` }>
                                                    <RestaurantPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_RECHERCHE }` }>
                                                    <RecherchePage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_PAIEMENT }` }>
                                                    <PaiementPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_DROIT_ACCES }` }>
                                                    <DroitAccesPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_CAMPS_RESERVATION }` }>
                                                    <CampsPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_CHOIX_UNITE }/AC` }>
                                                    <ChoixUnitePage showRecherche={ false }/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_ACTIVITE }` }>
                                                    <RenderActivite/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_FERMER_ENREGISTREMENT }` }>
                                                    <VerifieAuthentification/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_FERMER_ENREGISTREMENT_CAPTURE }` }>
                                                    <FermerEnregistrementEtCapture/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_CHOIX_UNITE }` }>
                                                    <ChoixUnitePage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_CHOIX_UNITE_CALENDRIER }` }>
                                                    <ChoixUniteCalendrier/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_ACHAT_FORFAIT }` }>
                                                    <AchatForfaitPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_ACTIVITE_RESERVATION }${ PARAM_TYPEUNITE_IDUNITE }` }>
                                                    <ActivityReservationPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_TIRAGE }` }>
                                                    <TiragePage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_TERRAIN }` }>
                                                    <TerrainPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_KIOSQUE }` }>
                                                    <KiosquePage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_LECTEUR_CARTES_MEMBRES }` }>
                                                    <LecteurCartesMembresPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_PROFILE }` }>
                                                    <ProfilePage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_RESERVATION }` }>
                                                    <ReservationPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_ANNULER_RESERVATION }` }>
                                                    <AnnulationReservationPage/>
                                                </Route>
                                                <Route exact path={ [PARAM_IDZEC_LANGUE, PARAM_IDZEC] }>
                                                    <HomePageRedirection/>
                                                </Route>
                                                {/* <Route path={`${PARAM_IDZEC_LANGUE}${navigation.PATH_BILLETTERIE_UNITE}/${PARAM_IDBILLET}`}> */ }
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_BILLETTERIE_UNITE }/:idBillet` }>
                                                    <BilletUnite/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_BILLETTERIE }` }>
                                                    <BilletteriePage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_PAIEMENT_COMPTES }` }>
                                                    <PaiementComptesPage/>
                                                </Route>
                                                <Route path={ `${ PARAM_IDZEC_LANGUE }${ navigation.PATH_EVENEMENT }` }>
                                                    <Evenement/>
                                                </Route>
                                                <Route path={`${PARAM_IDZEC_LANGUE}${navigation.PATH_BOOKING}`}>
                                                    <BookingPage />
                                                </Route>
                                                <Route path={`${PARAM_IDZEC_LANGUE}${navigation.PATH_CACHE}`}>
                                                    <CachePage />
                                                </Route>
                                                <Route>
                                                    <PageNotFound/>
                                                </Route>
                                            </Switch>
                                        </div>
                                    </div>
                                    <Footer/>
                                </GenericPopup>
                            </LoadingOverlay>
                        </Initializer>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default connect(mapStateToProps, {setAuthToken, setNoDossier, initializeShoppingCart, initialzePromotion, SaveProfilClient})(App);