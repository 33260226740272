import {v4 as uuidv4} from 'uuid';
import {SET_ALERT, REMOVE_ALERT} from './types';

const defaultTime = 8000; // default 6 secs

export const setAlert = (msg, alertType, timeout = defaultTime) => dispatch => { // NOTE: alertType affecte la couleur du popup. Ses valeurs possibles sont: 'success' et 'danger'.
    const id = uuidv4(); // Random long string
    dispatch({
        type: SET_ALERT,
        payload: {msg: msg || "Une erreur inattendue s'est produite", alertType, id}
    });
    setTimeout(() => dispatch({type: REMOVE_ALERT, payload: id}), timeout);
};

export const removeAlert = (id) => dispatch => {
    dispatch({type: REMOVE_ALERT, payload: id});
};
