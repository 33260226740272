import React, { useImperativeHandle, useRef, useCallback, useState } from 'react';
import SessionKeys from '../../session'
import { api, STATUS_OK, STATUS_NO_CONTENT, STATUS_BAD_REQUEST, STATUS_NOT_FOUND } from '../../server/api.js';

export async function isCacheNear(idZec, latitude, longitude, distance) {
    return await api.get(`Cache/getValidationCache?idZec=${idZec}&latitude=${latitude}&longitude=${longitude}&distance=${distance}`);
}

export async function ValideCoordinateCache(latitude, longitude) {

    let message = '';
    let idZec = 'zec-bsl';
    let inZEC = true;

    //LECTURE DISTANCE MINIMUM
    let distance = parseInt(sessionStorage.getItem(SessionKeys.DISTANCE_MINIMUM_CACHE));

    //LECTURE COORDONNE MIN ET MAX
    let longitudeMin = parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MINLNG));
    let longitudeMax = parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MAXLNG));

    //possible que les valeurs soit n�gatives dans la config
    if (longitudeMax < longitudeMin) {
        longitudeMin = longitudeMax;
        longitudeMax = parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MAXLNG));
    }

    let latitudeMin = parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MINLAT));
    let latitudeMax = parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MAXLAT));

    //possible si les valeurs sont n�gatives dans la config
    if (latitudeMax < latitudeMin) {
        latitudeMin = latitudeMax;
        latitudeMax = parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MAXLAT));
    }

    if (longitude < longitudeMin || longitude > longitudeMax)
        inZEC = false;

    if (latitude < latitudeMin || latitude > latitudeMax)
        inZEC = false;

    //VERIFIE SI COORDONNE DANS LE MINIMUM ET MAXIMUM
    //SI ERREUR
    if (inZEC !== true) {
        let error = "Assurez vous que la latitude " + latitude + " est comprise entre " + latitudeMin + " et " + latitudeMax
            + " et la longitude " + longitude + " est comprise entre " + longitudeMin + " et " + longitudeMax;
        return [false, error];
    }
    else {
        //VERIFIE SI LA CACHE EST TROP PRET D'UN AUTRE
    //    await isCacheNear(idZec, latitude, longitude, distance).then((distanceProche) => {
    //        if (distanceProche !== 0) {
    //            //Ces coordonn�es se situent trop pr�t d'une cache d�j� enr�gistr�e.
    //            let error = "Ces coordonn�es se situent trop pr�t d'une cache d�j� enr�gistr�e.";
    //            error = error + "Distance: " + distanceProche + " m�tres.";
    //            message = error;
    //        };


        //    });
        const [coordinateOk, message] = await api.get(`Cache/getValidationCache?idZec=${idZec}&latitude=${latitude}&longitude=${longitude}&distance=${distance}`, false).then(result => {
            if (result.status !== STATUS_OK)
                return [false, "Une erreur s'est produite et la v�rification na pas pu �tre faite."];

            return result.json();
        }).then(distanceProche => {
            if (distanceProche !== 0) {
                //Ces coordonn�es se situent trop pr�t d'une cache d�j� enr�gistr�e.
                let error = "Ces coordonn�es se situent trop pr�t d'une cache d�j� enr�gistr�e.";
                error = error + "Distance: " + distanceProche + " m�tres.";
                return [false, error];
            }
            else {
                return [true, '']
            };
        }).catch(e => {
            console.log(e);
            this.props.setAlert(e.message, "danger");
            return [false, e.message];
        }).finally(() => {
        });

        return [coordinateOk, message]

    //    await api.get(`Cache/getValidationCache?idZec=${idZec}&latitude=${latitude}&longitude=${longitude}&distance=${distance}`, false).then(result => {
    //        if (!result.keyValue !== 0) {
    //            const distanceProche = !result.keyValue;
    //            //Ces coordonn�es se situent trop pr�t d'une cache d�j� enr�gistr�e.
    //            let error = "Ces coordonn�es se situent trop pr�t d'une cache d�j� enr�gistr�e.";
    //            error = error + "Distance: " + distanceProche + " m�tres.";
    //            message = error;
    //        };
    //    }).then(() => {
    //        if (message !== '') {
    //            return [false, message]
    //        }
    //        else {
    //            return [true, '']
    //        }
    //    });
    }
}

