import "./cache-page.css";
import '../../../css/app.css';
import React, { Component } from "react";
import MapComponent from './mapcomponent';
import withNavigation from "../higher-order/navigation";
import { connect } from "react-redux";
import { valideCoordinateCache } from "./valideCoordinateCache";
import { getConfigurationByKeyName } from "../../server/services/configuration-service";
import SessionKeys from '../../session'
import jwtDecode from "jwt-decode";
import { setAlert } from './../actions/alert';

class CachePage extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.mounted = false; // Pour corriger un memory leak

        this.state = {
            searchLat: 0,
            searchLatMin: 0,
            searchLatSec: 0,
            searchLatitude: 0,
            searchLon: 0,
            searchLonMin: 0,
            searchLonSec: 0,
            searchLongitude: 0,
        };
        getConfigurationByKeyName("LATITUDE_MINIMUM_CACHE")
            .then((config) => {
                sessionStorage.setItem(SessionKeys.CACHE_COORDINATE_MINLAT, config?.keyValue.replace(",", "."));
            });
        getConfigurationByKeyName("LATITUDE_MAXIMUM_CACHE")
            .then((config) => {
                sessionStorage.setItem(SessionKeys.CACHE_COORDINATE_MAXLAT, config?.keyValue.replace(",", "."));
            });
        getConfigurationByKeyName("LONGITUDE_MINIMUM_CACHE")
            .then((config) => {
                sessionStorage.setItem(SessionKeys.CACHE_COORDINATE_MINLNG, config?.keyValue.replace(",", "."));
            });
        getConfigurationByKeyName("LONGITUDE_MAXIMUM_CACHE")
            .then((config) => {
                sessionStorage.setItem(SessionKeys.CACHE_COORDINATE_MAXLNG, config?.keyValue.replace(",", "."));
            });
        getConfigurationByKeyName("DISTANCE_MINIMUM_CACHE")
            .then((config) => {
                sessionStorage.setItem(SessionKeys.DISTANCE_MINIMUM_CACHE, config?.keyValue.replace(",", "."));
            });
    }

    componentDidMount() {
        this.mounted = true;
    }

    handleOnBlurThreePart = () => {
        this.newChangeInThreePart();
    }
    handleOnBlurLatOrLon = () => {
        this.myRef.current.validateMarker(0);
    }
    handleChangeLat = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLat: value })
    }
    handleChangeLatMin = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLatMin: value })
    }
    handleChangeLatSec = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLatSec: value })
    }
    handleChangeLatitude = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLatitude: value })
    }
    handleChangeLon = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLon: value })
    }
    handleChangeLonMin = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLonMin: value })
    }
    handleChangeLonSec = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLonSec: value })
    }
    handleChangeLongitude = (e) => {
        var value = Number(e.target.value);
        this.setState({ searchLongitude: value })
    }

    newChangeInThreePart = () => {

        let degrees = document.getElementById('Lat').value;
        let degreesAbs = Math.abs(Number(degrees))
        let minutes = document.getElementById('LatMin').value;
        let seconds = document.getElementById('LatSec').value;
        let latitude = degreesAbs + (Number(minutes) / 60) + (Number(seconds) / 3600);
        if (Number(degrees) !== degreesAbs)
            latitude = latitude * -1;
        degrees = document.getElementById('Lon').value;
        degreesAbs = Math.abs(Number(degrees))
        minutes = document.getElementById('LonMin').value;
        seconds = document.getElementById('LonSec').value;
        let longitude = degreesAbs + (Number(minutes) / 60) + (Number(seconds) / 3600);
        if (Number(degrees) !== degreesAbs)
            longitude = longitude * -1;

        this.setState({
            searchLatitude: latitude,
            searchLongitude: longitude,
        });

    }

    newClickSurLaCarte = (lat, lng) => {

        let latAbs = Math.abs(lat);
        let lngAbs = Math.abs(lng);

        const latDeg = Math.floor(latAbs);
        const latOri = Math.trunc(lat);
        const lngDeg = Math.floor(lngAbs);
        const lngOri = Math.trunc(lng);

        const latMinFloat = (latAbs - latDeg) * 60;
        const lngMinFloat = (lngAbs - lngDeg) * 60;

        const latMin = Math.floor(latMinFloat);
        const lngMin = Math.floor(lngMinFloat);

        const latSecFloat = (latMinFloat - latMin) * 60;
        const lngSecFloat = (lngMinFloat - lngMin) * 60;

        const latSec = Math.floor(latSecFloat);
        const lngSec = Math.floor(lngSecFloat);

        const latitude = lat;
        const longitude = lng;

        this.setState({
            searchLat: latOri,
            searchLatMin: latMin,
            searchLatSec: latSec,
            searchLatitude: latitude,
            searchLon: lngOri,
            searchLonMin: lngMin,
            searchLonSec: lngSec,
            searchLongitude: longitude,
        });
    };

    render() {
        return (
            <div className="cache-page">
                <div className="section ng-hide">
                    <div className="col-xs-12 col-sm-12">
                    </div>
                </div>

                <div className="reactive-table-container">

                    {this.props.authToken != null ?

                        <div className="votrecache">
                            <h1 className="secondary-color-1 center">Votre cache</h1>
                            <h4 className="secondary-color-1 bottom-separator"> </h4>

                            <div className="col-sm-12 col-xs-12">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th className="secondary-color-1-darker ng-binding">No. cache</th>
                                            <th className="secondary-color-1-darker ng-binding">Secteur</th>
                                            <th className="secondary-color-1-darker ng-binding">Latitude</th>
                                            <th className="secondary-color-1-darker ng-binding">Longitude</th>
                                            <th className="secondary-color-1-darker ng-binding">Année</th>

                                            <th className="secondary-color-1-darker ng-binding ng-hide" ng-show="cacheCtrl.IsAnyCacheRenewable &amp;&amp; true === false">Renouveler</th>
                                            <th className="secondary-color-1-darker ng-binding ng-hide" ng-show="cacheCtrl.IsAnyCacheRenewable &amp;&amp; true === false">Déplacer</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <span>Pour pouvoir renouveler votre cache, vous devez acheter votre forfait de chasse dans la section « Choisir un forfait ».</span>
                            </div>
                        </div>

                        :

                        <h1 id="valider-deplacer-cache-title" className="secondary-color-1 center ng-binding">
                            Valider cache
                        </h1>

                    }

                    <div className="col-sm-12 col-xs-12">
                        <h4 className="col-sm-12 col-xs-12 secondary-color-1 bottom-separator ng-binding">Coordonnées</h4>
                    </div>
                    <div className="col-sm-12 col-xs-12">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th className="secondary-color-1-darker"></th>
                                    <th className="secondary-color-1-darker ng-binding">Degrés</th>
                                    <th className="secondary-color-1-darker ng-binding">Minutes</th>
                                    <th className="secondary-color-1-darker ng-binding">Secondes</th>
                                    <th className="secondary-color-1-darker ng-binding">Degrés décimaux</th>
                                </tr>

                                <tr>
                                    <th className="secondary-color-1-darker">Latitude</th>
                                    <td><input id="Lat" value={this.state.searchLat} onChange={this.handleChangeLat} onBlur={ this.handleOnBlurThreePart } /></td>
                                    <td><input id="LatMin" value={this.state.searchLatMin} onChange={this.handleChangeLatMin} onBlur={ this.handleOnBlurThreePart } /></td>
                                    <td><input id="LatSec" value={this.state.searchLatSec} onChange={this.handleChangeLatSec} onBlur={ this.handleOnBlurThreePart } /></td>
                                    <td><input id="Latitude" value={Number(this.state.searchLatitude).toFixed(4)} onChange={this.handleChangeLatitude} onBlur={ this.handleOnBlurLatOrLon } /></td>
                                </tr>

                                <tr>
                                    <th className="secondary-color-1-darker">Longitude</th>
                                    <td><input id="Lon" value={this.state.searchLon} onChange={this.handleChangeLon} onBlur={ this.handleOnBlurThreePart } /></td>
                                    <td><input id="LonMin" value={this.state.searchLonMin} onChange={this.handleChangeLonMin} onBlur={ this.handleOnBlurThreePart } /></td>
                                    <td><input id="LonSec" value={this.state.searchLonSec} onChange={this.handleChangeLonSec} onBlur={ this.handleOnBlurThreePart } /></td>
                                    <td><input id="Longitude" value={Number(this.state.searchLongitude).toFixed(4)} onChange={this.handleChangeLongitude} onBlur={ this.handleOnBlurLatOrLon } /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="col-xs-12 col-sm-12 pull-right">
                        <div className="row">
                            <input type="hidden" id="postId" name="postId" value="34657" />
                            <div className="col-sm-12 col-xs-12">
                                <button type="button" className="btn btn-positive btn-end-of-section float-right ng-binding" onClick={e => this.myRef.current.validateMarker(1)}>
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>

                    <h1 className="secondary-color-1 center ng-binding">Vos caches sur la carte</h1>


                    <div className="CachePage">
                        <MapComponent newClickSurLaCarte={this.newClickSurLaCarte} ref={this.myRef} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authToken: state.authToken,
        parametresWeb: state.parametresWeb,
        configuration: state.configuration,
        overlay: state.overlay,
        entrepriseName: state.entreprise.nom,
    };
}

export default withNavigation(connect(mapStateToProps, { setAlert })(CachePage));
