import React, { useImperativeHandle, useRef, useCallback, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { apiKey } from './googleApi';
import overlaySVG from '../../../images/svg/region-caches/zec-bsl.svg';
import SessionKeys from '../../session'
import { ValideCoordinateCache } from "./valideCoordinateCache";
import { setAlert } from './../actions/alert';


const containerStyle = {
    width: '100%',
    height: '100vh',
};


const MapComponent = React.forwardRef((props, ref) => {
    const [markers, setMarkers] = useState([]);

    const mapRef = useRef(null);

    const coordinatesZEC = {
        minLat: parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MINLAT)),
        maxLat: parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MAXLAT)),
        minLng: parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MINLNG)),
        maxLng: parseFloat(sessionStorage.getItem(SessionKeys.CACHE_COORDINATE_MAXLNG)),
    };

    const center = {
        lat: (coordinatesZEC.minLat + coordinatesZEC.maxLat) / 2,
        lng: (coordinatesZEC.minLng + coordinatesZEC.maxLng) / 2,
    };
    useImperativeHandle(ref, (value) => ({
        validateMarker(value) {
            handlevalidateMarker(value);
        }
    }));


    function  handlevalidateMarker(verifier)  {
        setMarkers([]);

        let latitude = Number(document.getElementById('Latitude').value);
        let longitude = Number(document.getElementById('Longitude').value);

        if (mapRef.current) {
            setMarkers(prevMarkers => {
                return [{ lat: Number(latitude), lng: Number(longitude) }]
            });
        }

        if (verifier === 1)
        {
            const [coordinateOk, message] =  ValideCoordinateCache(latitude, longitude);
            if (coordinateOk) {
                alert('PARFAIT');
            }
            else {
                //alert(message);
                console.log(message);
                alert(message);
            }
        }

    }


    const mapRefCallback = useCallback(map => {

        if (map) {
            mapRef.current = map;

            const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(coordinatesZEC.minLat, coordinatesZEC.minLng),
                new window.google.maps.LatLng(coordinatesZEC.maxLat, coordinatesZEC.maxLng)
            );
            map.fitBounds(bounds);

            const imageBounds = {
                north: coordinatesZEC.maxLat,
                south: coordinatesZEC.minLat,
                east: coordinatesZEC.maxLng,
                west: coordinatesZEC.minLng,
            };

            // Add the GroundOverlay to the map
            new window.google.maps.GroundOverlay(
                overlaySVG,
                imageBounds, { clickable: false }
            ).setMap(map);
        }
    }, [])

    const onMapClick = (event) => {
        setMarkers([]);

        //Add marker
        if (mapRef.current) {
            setMarkers(prevMarkers => [...prevMarkers, { lat: event.latLng.lat(), lng: event.latLng.lng() }]);
        }
        props.newClickSurLaCarte(event.latLng.lat().toFixed(4), event.latLng.lng().toFixed(4));
    };


    return (
        <LoadScript googleMapsApiKey={apiKey} libraries={['places']}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoomControl={true}
                center={center}
                zoom={13}
                onLoad={mapRefCallback}
                mapTypeId={'satellite'}
                onClick={onMapClick}
            >
                {markers.map((marker, index) => (
                    //<Marker icon={{ url: `googlePin.png` }} key={index} position={marker} label={{ text: `Nouvelle cache`, color: '#FFEF00' }} />
                    <Marker key={index} position={marker} label={{ text: `Nouvelle cache`, color: '#FFEF00' }} />
                ))}
            </GoogleMap>
        </LoadScript>
    )
})

export default MapComponent;
