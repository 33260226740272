import React, {useEffect, useState} from 'react';
import {addToShoppingCart, CartItemType, getShop, setErrorAlert, updateShop} from '../actions/shop';
import {getCampInscription} from "../../server/services/catalogue-service";
import {connect, useDispatch} from 'react-redux';
import withNavigation from "../higher-order/navigation";
import Datetime from "react-datetime";
import 'moment/locale/fr';
import "react-datetime/css/react-datetime.css"
import moment from "moment/moment";
import {relations} from "../../enums";
import {setAlert} from "../actions/alert";

function CampDetails(props) {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        tuteur: {
            nom: '',
            prenom: '',
            telephone1: '',
            telephone2: '',
            protable: '',
            adresse: '',
            ville: '',
            codePostal: '',
            email: '',
        },
        inscription: {
            noAssuranceSociale: '',
            recuImpotNom: '',
            enfantNom: '',
            enfantPrenom: '',
            enfantDateNaissance: null,
            enfantSexe: '',
            assuranceMaladieNumero: '',
            assuranceMaladieExpiration: null,
            contact1Nom: '',
            contact1Relation: '',
            contact1Telephone1: '',
            contact1Telephone2: '',
            contact1Telephone3: '',
            contact2Nom: '',
            contact2Relation: '',
            contact2Telephone1: '',
            contact2Telephone2: '',
            contact2Telephone3: '',
            allergies: false,
            allergiesNotes: '',
            systemeInjection: false,
            systemeInjectionNotes: '',
            asthme: false,
            asthmeNotes: '',
            medication: false,
            medicationNotes: '',
            indicationsSpeciales: '',
            autoriserPhoto: false
        }
    });
    const [exp, setExp] = useState('');
    const [dateEnfant, setDateEnfant] = useState('');
    const [age, setAge] = useState(null);
    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [pers, setPers] = useState('');
    const [chandail, setChandail] = useState('');
    const Relation =
        [
            'Mère',
            'Père',
            'Grand-parent',
            'Conjoint(e) du parent',
            'Tuteur/Tutrice',
            'Ami',
            'Oncle/Tante',
            'Soeur/Frère',
            'Voisin',
            'Autres'
        ]
    const colors = [
        'Couleur ?',
        'rouge',
        'jaune',
        'bleu',
        'vert'
    ]
    useEffect(() => {
        if (props.shop.cart.length > 0) {
            props.shop.cart.forEach((item) => {
                if (item.type === CartItemType.CampJour && item.CampInscription) {
                    setForm(item.CampInscription);
                }
            })
        } else {
            props.history.push({pathname: `/${ props.match.params.idZec }/${ props.match.params.language || "fr" }/reservation-camps`});
        }
    }, [])
    const onPersChange = (e) => {
        setPers(e.target.name);
        // e.target.value = 'X'
    }

    const onChangeFormTuteur = (e) => {
        // if (e.target.value > 9 || e.target.value < 0) return
        const newForm = {...form};
        newForm.tuteur[e.target.name] = e.target.value;
        setForm(newForm);
    }
    const onChangeForm = (e) => {
        // if (e.target.value > 9 || e.target.value < 0) return
        const newForm = {...form};
        newForm.inscription[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const submitForm = async (e) => {
        if (form.tuteur.nom.length === 0) {
            props.setErrorAlert('Le nom du parent/tuteur est obligatoire');
            return;
        }
        if (form.tuteur.prenom.length === 0) {
            props.setErrorAlert('Le prénom du parent/tuteur est obligatoire');
            return;
        }
        if (form.tuteur.telephone1.length < 10) {
            props.setErrorAlert('Le téléphone du parent/tuteur est obligatoire');
            return;
        }
        if (form.tuteur.adresse.length === 0) {
            props.setErrorAlert("L'adresse du parent/tuteur est obligatoire");
            return;
        }
        if (form.tuteur.ville.length === 0) {
            props.setErrorAlert('La ville du parent/tuteur est obligatoire');
            return;
        }
        if (form.tuteur.codePostal.length === 0) {
            props.setErrorAlert('Le code postal du parent/tuteur est obligatoire');
            return;
        }
        if (form.tuteur.email.length === 0 || !form.tuteur.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            props.setErrorAlert('Le courriel du parent/tuteur est obligatoire');
            return;
        }

        if (form.inscription.recuImpotNom.length > 0 && form.inscription.noAssuranceSociale.length < 9) {
            props.setErrorAlert("Le Numéro d’assurance sociale pour le reçu d'impôt est obligatoire");
            return;
        }

        if (form.inscription.enfantNom === '') {
            props.setErrorAlert("Le nom de l'enfant est obligatoire");
            return;
        }
        if (form.inscription.enfantPrenom === '') {
            props.setErrorAlert("Le prénom de l'enfant est obligatoire");
            return;
        }
        if (!form.inscription.enfantDateNaissance || form.inscription.enfantDateNaissance === '') {
            props.setErrorAlert("La date de naissance de l'enfant est obligatoire");
            return;
        }
        if (form.inscription.enfantSexe === '') {
            props.setErrorAlert("Le sexe de l'enfant est obligatoire");
            return;
        }

        if (form.inscription.assuranceMaladieNumero === '') {
            props.setErrorAlert("Le numéro d'assurance maladie de l'enfant est obligatoire");
            return;
        }
        if (!form.inscription.assuranceMaladieExpiration || form.inscription.assuranceMaladieExpiration === '') {
            props.setErrorAlert("La date d'expiration du numéro d'assurance maladie de l'enfant est obligatoire");
            return;
        }
        if (form.inscription.contact1Nom === '') {
            props.setErrorAlert("Le nom de la personne à rejoindre en cas d’urgence est obligatoire");
            return;
        }
        if (form.inscription.contact1Relation === '') {
            props.setErrorAlert("La relation de la personne à rejoindre en cas d’urgence est obligatoire");
            return;
        }
        if (form.inscription.contact1Telephone1 === '') {
            props.setErrorAlert("Le téléphone de la personne à rejoindre en cas d’urgence est obligatoire");
            return;
        }

        if (form.inscription.allergies && form.inscription.allergiesNotes.length === 0) {
            props.setErrorAlert("Veuillez spécifier les allergies de l'enfant");
            return;
        }

        if (form.inscription.medication && form.inscription.medicationNotes.length === 0) {
            props.setErrorAlert("Veuillez spécifier les médications de l'enfant");
            return;
        }

        const cartIndex = props.shop.cart.findIndex(s => s.type === CartItemType.CampJour)
        const newItem = props.shop.cart[cartIndex];
        newItem["CampInscription"] = form;

        await props.updateShop(cartIndex, newItem, false);
        props.history.push({pathname: `/${ props.match.params.idZec }/${ props.match.params.language || "fr" }/reservation-camps/produit`});
        //props.gotoPaiement();
    }
    const inputTextAreaStyle = {width: '90%', height: "90px"};
    return <div>
        <span className="profile-span">CAMP DE JOUR { moment().format("yyyy") }</span>

        <div className='camp-form'>
            <b>FICHE D'INSCRIPTION</b>
            <div className='camp-input'>
                <label htmlFor="tuteur.nom">Nom du parent/tuteur : </label>
                <input type="text"
                       id="tuteur.nom" name="nom"
                       value={ form.tuteur.nom }
                       onChange={ onChangeFormTuteur }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 40 }
                />
                <label htmlFor="tuteur.prenom">Prénom du parent/tuteur : </label>
                <input type="text"
                       id="tuteur.prenom" name="prenom"
                       value={ form.tuteur.prenom }
                       onChange={ onChangeFormTuteur }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 40 }
                />
            </div>
            <div className='camp-input'>
                <label>Téléphone 1 : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;
                <input type="numeric"
                       id="tuteur.telephone1" name="telephone1"
                       value={ form.tuteur.telephone1 }
                    // style={{ float: 'right', width: '70%' }}
                       style={ {flex: '2', width: '27%'} }
                       minLength={ 0 }

                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeFormTuteur(e);
                       } }
                       maxLength={ 13 }
                />
                &nbsp;&nbsp;
                <label>Téléphone 2 : </label>
                <input type="numeric"
                       id="tuteur.telephone2" name="telephone2"
                       value={ form.tuteur.telephone2 }
                       style={ {width: '27%'} }
                       minLength={ 0 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeFormTuteur(e);
                       } }
                       maxLength={ 13 }
                />
                &nbsp;&nbsp;
                <label>Téléphone 3 : </label>
                <input type="numeric"
                       id="tuteur.telephone3" name="portable"
                       value={ form.tuteur.portable }
                       style={ {width: '27%'} }
                       minLength={ 0 }
                       maxLength={ 13 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeFormTuteur(e);
                       } }
                />
            </div>
            <div className='camp-input'>
                <label htmlFor="tuteur.adresse">Adresse : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <input type="text"
                       id="tuteur.adresse" name="adresse"
                       value={ form.tuteur.adresse }
                       onChange={ onChangeFormTuteur }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 100 }
                />
            </div>
            <div className='camp-input'>
                <label htmlFor="tuteur.ville">Ville : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;
                <input type="text"
                       id="tuteur.ville" name="ville"
                       value={ form.tuteur.ville }
                       onChange={ onChangeFormTuteur }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 40 }
                />
                <label htmlFor="tuteur.code_postal">Code postal : </label>
                <input type="text"
                       id="tuteur.codePostal" name="codePostal"
                       value={ form.tuteur.codePostal }
                       onChange={ onChangeFormTuteur }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 9 }
                />
            </div>
            <div className='camp-input'>
                <label htmlFor="recuImpotNom">Reçu d’impôt au nom de : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="text"
                       id="recuImpotNom" name="recuImpotNom"
                       value={ form.inscription.recuImpotNom }
                       onChange={ onChangeForm }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 100 }
                />
                <label id="small-nas" htmlFor="assuranceMaladieNumero">NAS : </label>
                <label id="large-nas" htmlFor="assuranceMaladieNumero">Numéro d’assurance sociale
                    : </label>
                <input type="text"
                       id="assuranceMaladieNumero" name="noAssuranceSociale"
                       minLength={ 0 } maxLength={ 9 }
                       value={ form.inscription.noAssuranceSociale }
                       onChange={ onChangeForm }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       placeholder='(123 456 789)'
                       style={ {width: '120px'} }
                       maxLength={ 9 }
                />

            </div>
            <span className='small-error'>
        (Sera envoyé par courriel en février { moment().add(1, "years").format("yyyy") })
      </span>

            <div className='camp-input'>
                <label htmlFor="tuteur.courriel">Courriel (obligatoire) : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <input type="text"
                       id="tuteur.courriel" name="email"
                       value={ form.tuteur.email }
                       onChange={ onChangeFormTuteur }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 255 }
                />
            </div>
            <span className='small-error'>
        Important : indiquer avec précision (en lettres moulées) UNE SEULE adresse courriel qui sera utilisée pour l’envoi de votre confirmation d’inscription et des documents d’information.
      </span>
        </div>


        <div className='camp-form'
        >
            <b>FICHE DE L'ENFANT</b>
            <div className='camp-input'>
                <label htmlFor="enfantNom">Nom : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;
                <input type="text"
                       id="enfantNom" name="enfantNom"
                       value={ form.inscription.enfantNom }
                       onChange={ onChangeForm }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 50 }
                />
            </div>
            <div className='camp-input'>
                <label htmlFor="enfantPrenom">Prénom : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input type="text"
                       id="enfantPrenom" name="enfantPrenom"
                       value={ form.inscription.enfantPrenom }
                       onChange={ onChangeForm }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 50 }
                />
            </div>

            <div className='camp-input'>
                <label htmlFor="lastName">Date de naissance : </label>
                <Datetime renderInput={ (props, openCalendar, closeCalendar) => <input { ...props } onKeyPress={ (e) => e.preventDefault() } onClick={ openCalendar }/> } isValidDate={ (current) => !current.isAfter(moment.now()) } onChange={ (date) => setForm((prev) => ({...prev, inscription: {...prev.inscription, enfantDateNaissance: date.toDate()}})) } value={ form.inscription.enfantDateNaissance } initialValue={ form.inscription.enfantDateNaissance } timeFormat={ false } dateFormat={ "DD/MM/YYYY" } input={ true } initialViewMode={ "years" } inputProps={ {placeholder: "(JJ/MM/AAAA)"} }/>

                <label htmlFor="lastName">Âge : </label>
                <input type="text"
                       value={ form.inscription.enfantDateNaissance && moment().diff(form.inscription.enfantDateNaissance, "years") }
                       id="age" name="age"
                       style={ {width: '45px'} }
                       disabled={ true }
                />
                <label htmlFor="enfantSexe">Sexe : </label>
                <select type="text"
                        id="enfantSexe" name="enfantSexe"
                        value={ form.inscription.enfantSexe }
                        onChange={ onChangeForm }
                        style={ {width: '100px'} }
                >
                    <option value={ "" }>Choisir</option>
                    <option value={ "F" }>Fille</option>
                    <option value={ "M" }>Garçon</option>
                </select>
            </div>
        </div>

        <div className='camp-form'>
            <b>FICHE DE SANTÉ</b>
            <div className='camp-input'>
                <label htmlFor="noAssuranceSociale">Numéro d’assurance maladie de l’enfant :
                </label>
            </div>
            <div className='camp-input'>
                <input type="text"
                       id="noAssuranceSociale" name="assuranceMaladieNumero"
                       value={ form.inscription.assuranceMaladieNumero }
                       onChange={ onChangeForm }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 12 }
                />
                <label htmlFor="assuranceMaladieExpiration">Exp.:
                </label>
                <Datetime renderInput={ (props, openCalendar, closeCalendar) => <input { ...props } onKeyPress={ (e) => e.preventDefault() }/> } isValidDate={ (current) => current.isAfter(moment.now()) } onChange={ (date) => setForm((prev) => ({...prev, inscription: {...prev.inscription, assuranceMaladieExpiration: date.toDate()}})) } value={ form.inscription.assuranceMaladieExpiration } timeFormat={ false } dateFormat={ "DD/MM/YYYY" } input={ true } initialViewMode={ "years" } inputProps={ {placeholder: "(JJ/MM/AAAA)"} }/>
            </div>
            <div className='camp-input'>
                <label htmlFor="lastName"><b>Personnes à rejoindre en cas d’urgence :</b>
                </label>
            </div>
            <div className='camp-input'>
                <label htmlFor="contact1Nom">Nom : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;
                <input type="text"
                       id="contact1Nom" name="contact1Nom"
                       value={ form.inscription.contact1Nom }
                       onChange={ onChangeForm }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 50 }
                />
            </div>
            <div className='camp-input'>
                <label htmlFor="contact1Relation">Lien avec l'enfant : </label>
                &nbsp;&nbsp;
                <select type="text"
                        id="contact1Relation" name="contact1Relation"
                        value={ form.inscription.contact1Relation }
                        onChange={ onChangeForm }
                        style={ {flex: '2', width: '100%'} }
                >
                    <option value="">Choisir</option>
                    { Relation.map((r, index) => {
                        return <option value={ index }>{ r }</option>
                    }) }
                </select>
            </div>
            <div className='camp-input'>
                <label htmlFor="contact1Telephone1">Téléphone 1 : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <input type="numeric"
                       id="contact1Telephone1" name="contact1Telephone1"
                       value={ form.inscription.contact1Telephone1 }
                       style={ {flex: '2', width: '27%'} }
                       minLength={ 0 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeForm(e);
                       } }
                       maxLength={ 13 }
                />
                &nbsp;&nbsp;
                <label>Téléphone 2 : </label>
                <input type="numeric"
                       id="contact1Telephone2" name="contact1Telephone2"
                       value={ form.inscription.contact1Telephone2 }
                       style={ {width: '27%'} }
                       minLength={ 0 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeForm(e);
                       } }
                       maxLength={ 13 }
                />
                &nbsp;&nbsp;
                <label>Téléphone 3 : </label>
                <input type="numeric"
                       id="contact1Telephone3" name="contact1Telephone3"
                       value={ form.inscription.contact1Telephone3 }
                       style={ {width: '27%'} }
                       minLength={ 0 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeForm(e);
                       } }
                       maxLength={ 13 }
                />
            </div>
            <div className='camp-input'>
                <label htmlFor="contact2Nom">Nom : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;
                <input type="text"
                       id="contact2Nom" name="contact2Nom"
                       value={ form.inscription.contact2Nom }
                       onChange={ onChangeForm }
                       style={ {flex: '2', width: '100%'} }
                       maxLength={ 50 }
                />
            </div>
            <div className='camp-input'>
                <label htmlFor="contact2Relation">Lien avec l'enfant : </label>
                &nbsp;&nbsp;
                <select type="text"
                        id="contact1Relation" name="contact2Relation"
                        value={ form.inscription.contact2Relation }
                        onChange={ onChangeForm }
                        style={ {flex: '2', width: '100%'} }
                >
                    <option value="">Choisir</option>
                    { Relation.map((r, index) => {
                        return <option value={ index }>{ r }</option>
                    }) }
                </select>
            </div>
            <div className='camp-input'>
                <label>Téléphone 1 : </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                <input type="numeric"
                       id="contact2Telephone1" name="contact2Telephone1"
                       value={ form.inscription.contact2Telephone1 }
                       style={ {flex: '2', width: '27%'} }
                       minLength={ 0 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeForm(e);
                       } }
                       maxLength={ 13 }
                />
                &nbsp;&nbsp;
                <label>Téléphone 2 : </label>
                <input type="numeric"
                       id="contact2Telephone2" name="contact2Telephone2"
                       value={ form.inscription.contact2Telephone2 }
                       style={ {width: '27%'} }
                       minLength={ 0 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeForm(e);
                       } }
                       maxLength={ 13 }
                />
                &nbsp;&nbsp;
                <label>Téléphone 3 : </label>
                <input type="numeric"
                       id="contact2telephone3" name="contact2Telephone3"
                       value={ form.inscription.contact2Telephone3 }
                       style={ {width: '27%'} }
                       minLength={ 0 }
                       onKeyPress={ (e) => {
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       } }
                       onChange={ (e) => {

                           onChangeForm(e);
                       } }
                       maxLength={ 13 }
                />
            </div>

            <div style={ {display: "flex", flexDirection: "column", gap: "0.5rem"} }>
                <div style={ {display: "flex", gap: "1rem"} }>
                    <label style={ {flex: "1"} }>Votre enfant a-t-il des <b>allergies</b> ?</label>
                    <div style={ {display: "flex", gap: "1rem", textAlign: "center", paddingRight: "15px"} }>
                        <span>Oui</span>
                        <span>Non</span>
                    </div>
                </div>
                <div style={ {display: "flex", gap: "1rem"} }>
                    <textarea
                        style={ inputTextAreaStyle }
                        value={ form.inscription.allergiesNotes }
                        name="allergiesNotes"
                        onChange={ onChangeForm }
                        type="text"
                        maxLength={ 4000 }
                    />
                    <div style={ {display: "flex", width: "100px", gap: "1rem"} }>
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "allergies", value: true}}) }
                               checked={ form.inscription.allergies }
                        />
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "allergies", value: false}}) }
                               checked={ !form.inscription.allergies }
                        />
                    </div>
                </div>
            </div>
            <div style={ {display: "flex", flexDirection: "column", gap: "0.5rem"} }>
                <div style={ {display: "flex", flexDirection: "column"} }>
                    <label>Votre enfant possède-t-il un <b>syst. d’injection</b> ?</label>
                    <small>(ex. : Épipen/autre)</small>
                </div>
                <div style={ {display: "flex", gap: "1rem"} }>
                    <textarea
                        style={ inputTextAreaStyle }
                        value={ form.inscription.systemeInjectionNotes }
                        name="systemeInjectionNotes"
                        onChange={ onChangeForm }
                        type="text"
                        maxLength={ 4000 }
                    />
                    <div style={ {display: "flex", width: "100px", gap: "1rem"} }>
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "systemeInjection", value: true}}) }
                               checked={ form.inscription.systemeInjection }
                        />
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "systemeInjection", value: false}}) }
                               checked={ !form.inscription.systemeInjection }
                        />
                    </div>
                </div>
            </div>
            <div style={ {display: "flex", flexDirection: "column", gap: "0.5rem"} }>
                <div style={ {display: "flex", flexDirection: "column"} }>
                    <label>Votre enfant fait-il de l’<b>asthme</b> ?</label>
                </div>
                <div style={ {display: "flex", gap: "1rem"} }>
                    <textarea
                        style={ inputTextAreaStyle }
                        value={ form.inscription.asthmeNotes }
                        name="asthmeNotes"
                        onChange={ onChangeForm }
                        type="text"
                        maxLength={ 4000 }
                    />
                    <div style={ {display: "flex", width: "100px", gap: "1rem"} }>
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "asthme", value: true}}) }
                               checked={ form.inscription.asthme }
                        />
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "asthme", value: false}}) }
                               checked={ !form.inscription.asthme }
                        />
                    </div>
                </div>
            </div>
            <div style={ {display: "flex", flexDirection: "column", gap: "0.5rem"} }>
                <div style={ {display: "flex", flexDirection: "column"} }>
                    <label>Votre enfant a-t-il besoin de <b>médication</b> ?</label>
                    <small>(ex. : Inhalateur ou comprimés)</small>
                </div>
                <div style={ {display: "flex", gap: "1rem"} }>
                    <textarea
                        style={ inputTextAreaStyle }
                        value={ form.inscription.medicationNotes }
                        name="medicationNotes"
                        onChange={ onChangeForm }
                        type="text"
                        maxLength={ 4000 }
                    />
                    <div style={ {display: "flex", width: "100px", gap: "1rem"} }>
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "medication", value: true}}) }
                               checked={ form.inscription.medication }
                        />
                        <input type="checkbox"
                               id="allergies" name="allergies"
                               onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "medication", value: false}}) }
                               checked={ !form.inscription.medication }
                        />
                    </div>
                </div>
            </div>
            <div>
                <label labelFor="indicationsSpeciales"><b>Autres indications spéciales à nous préciser</b> ?</label>
                <small>(ex. : TDAH, restrictions physiques, etc.)
                </small>
                <textarea
                    id="indicationsSpeciales" name="indicationsSpeciales"
                    style={ {width: '100%', height: "100px"} }
                    value={ form.inscription.indicationsSpeciales }
                    onChange={ onChangeForm }
                    maxLength={ 4000 }
                />
            </div>
        </div>

        <div className='camp-form'
             style={ {textAlign: 'center'} }
        >
            J’AUTORISE l’utilisation des <b>photos</b> et <b>vidéos</b> du
            camp à des fins de promotions sur notre site web,
            page Facebook ou publicités dans les journaux

            <div style={ {paddingTop: "10px", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem"} }>
                <span>Oui</span>
                <input type="checkbox"
                       id="photo"
                       onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "autoriserPhoto", value: true}}) }
                       checked={ form.inscription.autoriserPhoto }
                />
                <span>Non</span>
                <input type="checkbox"
                       id="photo"
                       onChange={ (e) => onChangeForm({...e, target: {...e.target, name: "autoriserPhoto", value: false}}) }
                       checked={ !form.inscription.autoriserPhoto }
                />
            </div>

            <br/>
        </div>

        <div className="billet-unite-achat-btn unselectable billetterie-page-retour-btn ">
      <span className="clickable" onClick={ () => {
          props.history.push({pathname: `/${ props.match.params.idZec }/${ props.match.params.language || "fr" }/reservation-camps`});
      } }>
          PRÉCÉDENT
      </span>
            <span
                className={ `clickable right` }
                onClick={ submitForm }>
        CONFIRMEZ
      </span>
        </div>
    </div>
}

const mapStateToProps = (state, ownProps) => ({
    shop: state.shop, entrepriseName: state.entreprise.nom
});

const dispatchProps = {
    getShop, addToShoppingCart, updateShop, setErrorAlert
};
export default withNavigation(connect(mapStateToProps, dispatchProps)(CampDetails))