import React from "react";
import PropTypes from "prop-types";

import "./SpinInput.css";

const SpinInput = (props) => {
    const minValue = props.min;
    const maxValue = props.max || 999999999;

    const onChange = (event) => {
        if (minValue >= 0 || parseInt(event.target.value) >= 0) {
            event.target.value = Math.abs(parseInt(event.target.value) > maxValue ? maxValue : parseInt(event.target.value));
        } else {
            event.target.value = parseInt(event.target.value) < minValue ? minValue : parseInt(event.target.value);
        }

        props.onChange(event);
    };

    const valueIncrement = () => {
        const newValue = (parseInt(props.value) || 0) + 1 > maxValue ? maxValue : (parseInt(props.value) || 0) + 1;
        props.onChange({target: {name: props.name, value: newValue}});
    };

    const valueDecrement = () => {
        const newValue = (parseInt(props.value) || 0) - 1 < minValue ? minValue : (parseInt(props.value) || 0) - 1;
        props.onChange({target: {name: props.name, value: newValue}});
    };

    return (
        <div className="SpinInput" style={ props.style }>
            <input name={ props.name || "" } type="number" value={ props.value } max={ maxValue } min={ minValue } onChange={ onChange }/>
            <div className="Arrow">
                <button type="button" className="Up" onClick={ valueIncrement } tabIndex="-1">
                    ▲
                </button>
                <button type="button" className="Down" onClick={ valueDecrement } tabIndex="-1">
                    ▼
                </button>
            </div>
        </div>
    );
};

SpinInput.propTypes = {
    value: PropTypes.number.isRequired,
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object
};

export default SpinInput;
