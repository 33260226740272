import React from "react";
import {
  SHOW_POPUP,
  HIDE_POPUP,
} from "./types";

export const showYesNoPopup = options => dispatch => {
  try {
    dispatch({
      type: SHOW_POPUP,
      title: options.title,
      titleStyle: options.titleStyle,
      body: options.body || (
        <div className={options.bodyClass}>
          <span className="label" style={{textTransform: 'initial'}}>{options.bodyText || ""}</span>
        </div>
      ),
      footer: options.footer || (
        <div className={options.footerClass}>
          <div className="binary-btn-holder">
            <div className="binary-btn-wrapper positive" onClick={options.onYesClick || (() => {})}>
              <span>{options.yesText || "OUI"}</span>
            </div>
            <div className="binary-btn-wrapper negative" onClick={options.onNoClick || (e =>dispatch(hidePopup()))}>
              <span>{options.noText || "NON"}</span>
            </div>
          </div>
        </div>
      ),
      footerStyle: options.footerStyle,
      onCloseClick: options.onCloseClick || (e =>dispatch(hidePopup())),
      contentStyle: options.contentStyle || {},
    });
  } catch (err) {
    console.log(err);
  }
};

export const showOkPopup = options => dispatch => {
  try {
    dispatch({
      type: SHOW_POPUP,
      title: options.title,
      titleStyle: options.titleStyle,
      body: options.body || (
        <div>
          <span className="label" style={{textTransform: 'initial'}}>{options.bodyText || ""}</span>
        </div>
      ),
      footer: options.footer || (
        <div className="singular-btn-holder">
          <div className="singular-btn-wrapper positive" onClick={options.onOkClick || (e =>dispatch(hidePopup()))}>
            <span>{options.okText || "OK"}</span>
          </div>
        </div>
      ),
      footerStyle: options.footerStyle,
      onCloseClick: options.onCloseClick || (e =>dispatch(hidePopup())),
      contentStyle: options.contentStyle || {},
      bodyClass: options.bodyClass || {},
      footerClass: options.footerClass || {},
    });
  } catch (err) {
    console.log(err);
  }
};

export const showPopup = options => dispatch => {
  try {
    dispatch({
      type: SHOW_POPUP,
      title: options.title,
      titleStyle: options.titleStyle,
      body: options.body,
      footer: options.footer,
      footerStyle: options.footerStyle,
      onCloseClick: options.onCloseClick || (e =>dispatch(hidePopup())),
      contentStyle: options.contentStyle || {},
      contentClass: options.contentClass || "",
      bodyClass: options.bodyClass || {},
      footerClass: options.footerClass || {},
    });
  } catch (err) {
    console.log(err);
  }
};

export const hidePopup = () => dispatch => {
  try {
    dispatch({
      type: HIDE_POPUP,
    });
  } catch (err) {
    console.log(err);
  }
};