import React, {useEffect, useState, StrictMode, useRef, useMemo, useCallback} from "react";
import {connect} from "react-redux";
import Calendar from "../calendar.js";
import "../../../css/choix-unite-liste.css";
import "../../../css/unite-card.css";
import {setQte, setReload, setQteMax, setHeureFin, setHeureDebut, setIdUniteCategorie, setCategorieActivite, setIdtypeUniteActivite,} from "../actions/form-pop-active.js";
import ModalActivite from "../popups/activite.js/modal.js";
import LayoutContent from "../unite-card-layout-content.js";
import {getCategorieActivitesDisponibilites, getImageUnitesActivites} from '../../server/services/unite-service.js';
import Translator from "../translator";
import {filtrerUnites} from "../../utils/activite/filter-unites.js";
import ActiviteList from "./activites-list.js";
import {getFromStorage, setFromStorage,} from "../../utils/localStorageUtils.js";
import {formatDateYMD} from "../../utils/formatting.js";
import {setAlert} from '../actions/alert';
import {setDisponibilitesUnites} from "../actions/form-pop-active.js";
import {transformData} from "../../utils/activite/"
import {getUnitesActivites} from "../../server/services/unite-service.js";
import {addToShoppingCart} from "../actions/shop";
import {UpdateCategorieActiviteList, addpanier} from '../../utils/activite/';
import loadingImage from "../../../images/loading.svg";

const RenderActivite = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typePeriode, setTypePeriode] = useState()
    const [minDate, setMinDate] = useState(new Date());
    const [uniteCategorieList, setCategorieActiviteList] = useState([]);
    const [dateState, setDate] = useState(new Date());
    const [nbreDisponible, setNbDisponible] = useState();
    const [prix, setPrix] = useState();
    const [nameActivite, setNameActivite] = useState();
    const [selectedActivite, setselectedActivite] = useState();
    const [calendarModal, setcalendarModal] = useState(false);
    const [dureePeriode, setDureePeriode] = useState()
    const [load, setLoad] = useState(false)
    const [categorieImages, setCategorieImages] = useState([])
    const [texts, setTexts] = useState(Translator("fr", "TXT_UNITE_CARD"));
    const [isDataLoading, setIsDataLoading] = useState(true);
    const {thumbnail} = props;

    useEffect(() => {
        if (!isModalOpen) {
            setcalendarModal(false);
        }
    }, [isModalOpen]);

    useEffect(() => {
        setLoad(true);

    }, [dateState]);

    useEffect(() => {

        // setLoad(true)
        getutilsFromLocalStorage();
        let minDate = new Date();
        minDate.setHours(0);
        minDate.setMinutes(0);
        minDate.setSeconds(0);
        minDate.setMilliseconds(0);
        setMinDate(minDate)
    }, [dateState, props.reload, props.heureDebut, props.heureFin]);

    const getutilsFromLocalStorage = useCallback(async () => {
        setIsDataLoading(true);
        const idTypeUnite = getFromStorage("idTypeUnite");
        props.setIdtypeUniteActivite(idTypeUnite);
        if (idTypeUnite) {
            try {
                const uniteCategorie = await getUnitesActivites(idTypeUnite);
                const result = await UpdateCategorieActiviteList(idTypeUnite, formatDateYMD(dateState), uniteCategorie);
                const resultet = await getCategorieActivitesDisponibilites(props.idUniteCategorie, formatDateYMD(dateState));
                props.setDisponibilitesUnites(transformData(resultet));
                props.setQteMax(filtrerUnites(transformData(resultet), props.heureDebut, props.heureFin, dateState).length);
                setCategorieActiviteList(result.sort((a, b) => a.categorie.localeCompare(b.categorie)));
                setLoad(false)
            } catch (error) {

            }

        }
        setIsDataLoading(false);
    }, [props, dateState]);

    const onchangeDate = (date) => {
        if (date === null) return false;
        setDate(date);
    };

    const togglePop = useCallback(async (idUniteCategorie, nbDisponible, typePeriode, montant, name, dureePeriode) => {
        setIsModalOpen(true);
        setNameActivite(name)
        setTypePeriode(typePeriode)
        setDureePeriode(dureePeriode)
        setPrix(montant)
        props.setQte(1)
        props.setIdUniteCategorie(idUniteCategorie);
        setNbDisponible(nbDisponible); // mettre ceci dans redux pour ne plus faire qteMAx
    }, [props]);

    const addpanierHandler = async () => {

        const result = filtrerUnites(props.disponibiliteUnites, props.heureDebut, props.heureFin, dateState)
        if (result.length > 0) {
            props.setQteMax(result.length)
            props.setReload(false)
            addpanier({...props, heureFin: props.heureFin || selectedActivite.heureFin}, nameActivite, prix, dateState, typePeriode, result);

        } else {
            props.setAlert(`il n'ya pas de disponibilite pour cette unite`, 'danger');
        }
    }

    return (<StrictMode>
        <div className="no-recherche">
            { (!isModalOpen && isDataLoading && <div style={ {backgroundColor: "#00000030", zIndex: 99999, width: "100%", position: "absolute", display: "flex", backdropFilter: "blur(2px)"} }>
                <img src={ loadingImage } alt="chargement" style={ {flex: 1} }/>
            </div>) }
            <>
                { uniteCategorieList && uniteCategorieList.length > 0 ? (
                    <div className="content-render-activite" style={ {display: "flex", position: "relative", flexDirection: "row", width: "100%", alignItems: "flex-start", paddingLeft: "25px", paddingRight: "25px", paddingTop: "12px",} }>

                        <div className="unite-card-disponibilites content-calendar-activite">
                            <Calendar
                                // isActivite={true}
                                dateActivite={ dateState }
                                minDate={ minDate }
                                indisponibilites={ [] }
                                onDayClick={ (date) => onchangeDate(date) }
                            />
                        </div>

                        <div style={ {width: "100%"} }>

                            { uniteCategorieList.map((activite, index) => (<LayoutContent
                                key={ `${ activite.idUniteCategorie }-${ index }` }
                                texts={ texts }
                                prixBase={ activite.prix }
                                isActive={ true }
                                typePeriode={ activite.tyPeriode }
                                classModifier={ "no-calendrier no-icons " }
                                classNameProps={ "unite-card " }
                                onClickToggle={ () => {
                                    setselectedActivite(activite);
                                    togglePop(activite.idUniteCategorie, activite.nbDisponible, activite.typePeriode, activite.prix, activite.categorie, activite.dureePeriode)
                                } }
                                showConsulter={true}
                            >
                                <ActiviteList
                                    key={ props.key }
                                    images={ activite.images || [] }
                                    nbreDisponible={ activite.nbDisponible }
                                    onClickAjouterPanier={ props.onClickAjouterPanier }
                                    categotieUniteActivte={ activite.categorie }
                                    descriptionUniteACtivite={ activite.descriptionWebFr }
                                    classModifier={ "no-calendrier no-icons" }
                                />
                            </LayoutContent>)) }
                        </div>
                    </div>) : (<div id='aucune-unite-wrapper'>{ (isDataLoading && <img src={ loadingImage } alt="chargement"/>) || <span>AUCUNE UNITÉ NE CORRESPOND AUX CRITÈRES DE RECHERCHE.</span> }
                </div>) }
            </>

            {/* Pop */ }
            <div style={ {position: "relative"} }>
                { isModalOpen && (<ModalActivite
                    prix={ prix }
                    minDate={ minDate }
                    date={ dateState }
                    dureePeriode={ dureePeriode }
                    nbreDisponible={ props.qteMax }
                    typePeriode={ typePeriode }
                    calendarModal={ calendarModal }
                    isDataLoading={ isDataLoading }
                    onClickAjouterPanier={ () => addpanierHandler() }
                    _setDate={ (dateSelect) => setDate(dateSelect) }
                    _setcalendarModal={ (value) => setcalendarModal(value) }
                    onClose={ () => {
                        setIsModalOpen(!isModalOpen);
                        setDate(dateState)
                    } }
                />) }
            </div>
        </div>
    </StrictMode>);
};

const mapStateToProps = (state) => ({
    cart: state.shop.cart, qte: state.formPopActive.qte, qteMax: state.formPopActive.qteMax, reload: state.formPopActive.reload, heureDebut: state.formPopActive.heureDebut, heureFin: state.formPopActive.heureFin, disponibiliteUnites: state.formPopActive.disponibiliteUnites, categoriesActivitelist: state.categoriesActivite.categorie, idTypeUnite: state.categoriesActivite.idTypeUniteActivite, idUniteCategorie: state.formPopActive.idUniteCategorie,
});

export default connect(mapStateToProps, {setHeureDebut, setHeureFin, setCategorieActivite, setIdtypeUniteActivite, setIdUniteCategorie, addToShoppingCart, setAlert, setQte, setReload, setQteMax, setDisponibilitesUnites})(RenderActivite);
