import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {getConfiguration, getNowTaxe, getParametresClient} from "../server/services/configuration-service";
import {getNomEntreprise, getContactEntreprise, getLogoEntreprise, getNoEtablissementEntreprise, getSiteWebEntreprise, getEntrepriseIsFromQuebec} from "../server/services/entreprise-service";
import {setParametresWeb} from "./actions/parametres-web";
import {setFraisAdmin, setTauxTaxes} from "./actions/shop";
import {setConfiguration} from "./actions/configuration";
import {setIdZec, setLanguage} from "./actions/route-location";
import {setEntrepriseName, setEntrepriseContact, setEntrepriseLogo, setEntrepriseNoEtablissement, setEntrepriseSiteWeb, setEntrepriseIsFromQuebec} from "./actions/entreprise";
import PageNotFound from "./page-not-found";

const LS_LANGUAGES = ["fr", "en", undefined]; // undefined pour #/cedres_test ou #/cedres_test//reservation

function mapStateToProps(state) {
    return {entreprise: state.entreprise};
}

function camelcaseKeys(config) {
    // Camelcase les clefs (FACTURE_POURCENTAGE_PAIEMENT1 devient facturePourcentagePaiement1, etc.)
    for (var keyName in config) {
        let newKeyName = keyName[0].toLowerCase();

        for (var i = 1; i < keyName.length; i++) {
            if (keyName[i] === "_") ;
            else if (keyName[i - 1] === "_")
                // We don't keep characters '_'
                newKeyName += keyName[i];
            else newKeyName += keyName[i].toLowerCase();
        }

        config[newKeyName] = config[keyName];
        delete config[keyName];
    }
}

function Initializer(props) {
    const {idZec, language} = useParams();
    const [zecInitialized, setZecInitialized] = useState(false);
    const [isPathValid, setIsPathValid] = useState(true);

    useEffect(() => {
        getNomEntreprise().then((nomEntreprise) => {
            // const pathValid = nomEntreprise.length > 0; //LS_LANGUAGES.includes(language); on ne peut plus se fier a langue car maintenant on met francais si la langue n'existe pas
            const pathValid = LS_LANGUAGES.includes(language);
            setIsPathValid(pathValid);
            props.setLanguage(language || "fr");
            props.setEntrepriseName(nomEntreprise);
            props.setIdZec(idZec);
            setZecInitialized(true);
        });
    }, [idZec, language]);

    useEffect(() => {
        getContactEntreprise().then((contactEntreprise) => {
            props.setEntrepriseContact(contactEntreprise);
        });
    }, [idZec]);

    useEffect(() => {
        getNoEtablissementEntreprise().then((noEtablissement) => {
            props.setEntrepriseNoEtablissement(noEtablissement);
        });
    }, [idZec]);

    useEffect(() => {
        getSiteWebEntreprise().then((siteweb) => {
            props.setEntrepriseSiteWeb(siteweb);
        });
    }, [idZec]);

    useEffect(() => {
        getLogoEntreprise().then((logoEntreprise) => {
            props.setEntrepriseLogo(logoEntreprise);
        });
    }, [idZec]);

    useEffect(() => {
        getEntrepriseIsFromQuebec().then((isFromQuebec) => {
            props.setEntrepriseIsFromQuebec(isFromQuebec);
        });
    }, [idZec]);

    useEffect(() => {
        if (zecInitialized) {
            getParametresClient().then((parametresWeb) => {
                props.setParametresWeb(parametresWeb);
                props.setFraisAdmin({fixe: parametresWeb.montantFraisFixe, pourcent: parametresWeb.montantFraisPourcent});
            });
        }
    }, [zecInitialized, idZec]);

    useEffect(() => {
        if (zecInitialized) {
            getNowTaxe().then((taxes) => {
                props.setTauxTaxes({tps: taxes.tauxFederal, tvq: taxes.tauxProvincial, hebergement: taxes.hebergement});
            });
        }
    }, [zecInitialized, idZec, props.setTauxTaxes]);

    useEffect(() => {
        if (zecInitialized) {
            getConfiguration().then((config) => {
                camelcaseKeys(config);
                props.setConfiguration(config);
            });
        }
    }, [zecInitialized, idZec]);

    return !zecInitialized ? <div/> : !isPathValid ? <PageNotFound error="identifiant de compagnie ou langue introuvable"/> : <div>{ props.children }</div>;
}

export default connect(mapStateToProps, {setParametresWeb, setFraisAdmin, setTauxTaxes, setConfiguration, setIdZec, setLanguage, setEntrepriseName, setEntrepriseContact, setEntrepriseLogo, setEntrepriseIsFromQuebec, setEntrepriseNoEtablissement, setEntrepriseSiteWeb})(Initializer);
