import {api} from "../api";

/*
 * Service utilisé pour la gestion les produits
 */

export function getForfaits(idProfile = "") {
    // Guid.TryParse côté serveur retourne null si idProfile n'est pas un Guid, ce qui est correcte.
    return api.get("Catalogue/Forfaits?idProfile=" + idProfile);
}

export function getProducts(postBody) {
    return api.post("Catalogue/Boutique", postBody);
}

export function getCategoriesProduitsBoutique() {
    return api.get("ProduitCategorie/CategoriesProduitsBoutique");
}

export function getProductById(idProduit) {
    return api.get(`Catalogue/${idProduit}`);
}

export function getProductPrixParTrancheAge(idProduit, trancheAges) {
    return api.post(`Catalogue/${ idProduit }/PrixParTrancheAge`, JSON.stringify(trancheAges));
}

export function getBilletterie(idProduit = "") {
    return api.get("Catalogue/Billeterie?idProduit=" + idProduit);
}

export function getBilletterieImage(idProduit = "") {
    return api.get("Catalogue/BilleterieImage/" + idProduit);
}

export function getTirage() {
    return api.get("Tirage/TirageDisponible");
}

export function getQuantiteEnMain(idProduit = "") {
    return api.get("Catalogue/QuantiteEnMain/" + idProduit);
}

export function searchForfaits(includeFields = "") {
    return api.get(`Catalogue/forfaits/search?include=${includeFields}`);
}

export function searchProducts(includeFields = "{}", includePrix = "", idProfile = null) {
    return api.get(`Catalogue/forfaits/search?include=${includeFields}&prix=${includePrix}&idProfile=${idProfile}`);
}

export function getAutobusList(idMunicipaite) {
    return api.get(`Catalogue/AutobusList/${idMunicipaite}`);
}

export function getMunicipaliteList() {
    return api.get("Municipalite/liste");
}

export function getCampInscription() {
    return api.get("CampInscription/default");
}

export function getChandailByIdProduct() {
    return api.get(`Catalogue/campjour/produits`);
}

export function getCatalogueImage(guid) {
    return api.get(`Catalogue/${guid}/Images`);
}

export function getProduitPrix(guid) {
    return api.get(`Catalogue/${guid}/Prix`);
}

export function getTermeEtCondition(id) {
    return api.get(`TermeEtCondition/getTermeEtCondition/${id}`);
}