import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import withNavigation from "../higher-order/navigation";
import {getBilletterie, getBilletterieImage} from "../../server/services/catalogue-service";
import "./billetterie-page.css";
import {getShop, CartItemType} from "../actions/shop";
import FacturePanierPopup from "../popups/facture-panier-popup";
import SommaireRightPanel from "../achat-forfait/sommaire-right-panel";
import CartSvg from "../svg/cart";
import {useSeoTitle} from "../../utils/hooks";
import Translator from "../translator";

function BilletteriePage(props) {
    const TEXTS = Translator(props.match.params.language, "TXT_BILLETTERIE_PAGE");
    useSeoTitle(TEXTS.SEO_TITLE);
    const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingImage, setLoadingImage] = useState(true);
    const [billetterie, setBilletterie] = useState([]);

    useEffect(async () => {
        const billets = await getBilletterie();
        await setBilletterie(billets);
        await setLoading(false);
        const promiseImage = billets.map(item => getBilletterieImage(item.idProduit).then(imageBillet => item.produitImage = imageBillet));
        await Promise.all(promiseImage)

        await setBilletterie(billets);
        await setLoadingImage(false);
    }, []);


    const renderContents = (item) => {
        const thumbnail = item.produitImage.length === 0
            ? require("../../../images/no-image.png")
            : "data:image/png;base64," + item.produitImage[0].image;

        return (
            <tr key={ item.description }>
                <td className="col1">
                    <img className="image-evenement" src={ isLoadingImage ? require("../../../images/loading.svg") : thumbnail }></img>
                </td>
                <td className="col2">
                    <span className="billetterie-header">{ item.description }</span>
                    <p className="date">
                        { item.descriptionWebFrancaise.split("\n").map((item, key) => (
                            <span key={ key }>
                { item }
                                <br/>
              </span>
                        )) }
                    </p>
                    <div className="clear">&nbsp;</div>
                    <p className="text-left large-text-justify"></p>
                    <div className="bulles">
                        {/* <div className="bulle-indicateur">PRIX</div> */ }
                        { item.produitPrix.map((cur, key) => {
                            return (
                                <div className="bulle hidden-sm" key={ key }>
                                    <strong>{ cur.description }</strong>
                                    <br/>
                                    { cur.prix.toFixed(2) } $
                                </div>
                            );
                        }) }
                    </div>
                    <button className="achat-forfait-card-buyable-list-item-montant button-achat-billet" onClick={ () => props.gotoBilletUnite(item) }>
                        { TEXTS.BUY }
                    </button>
                    <div className="clear">&nbsp;</div>
                </td>
            </tr>
        );
    };

    const titleSpanClass = props.onTitleClick ? "clickable" : "";
    return (
        <div id="billetterie-page-container">
            <div className="recherche-component billeterie-div-filter"/>
            <div className="achat-forfait-card-container">
                <div className="card-sub-container">
                    <div className="card-title achat-forfait-tab">
                        <span className={ titleSpanClass }>{ TEXTS.SEO_TITLE }</span>
                    </div>

                    { isLoading
                        ? <span className="billetterie-header">{ TEXTS.LOADING }</span>
                        : billetterie && billetterie.length
                            ?
                            <table style={ {width: '100%'} }>
                                <tbody>{ billetterie.map(renderContents) }</tbody>
                            </table>
                            :
                            <span className="billetterie-header">{ TEXTS.NO_DATA }</span>
                    }
                </div>
            </div>

            <FacturePanierPopup showByType="billet" handleClose={ () => setIsShoppingCartOpen(false) } isOpen={ isShoppingCartOpen }/>
            <div className="div-filtrer" onClick={ () => setIsShoppingCartOpen(true) }>
        <span className="icon">
          <CartSvg width="30" height="30"/>
        </span>
            </div>
            <div className="sommaire-right-panel max-width sommaire-large-screen">
                <SommaireRightPanel message={ TEXTS.title1 } confirm={ () => props.goTo("/panier", "PANIER") } confirmTitle={ TEXTS.confirmTitle } confirmError={ TEXTS.confirmError } showByType={ CartItemType.Billetterie } canConfirm={ props.shop.cart.length > 0 }/>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    shop: state.shop
});
export default withNavigation(connect(mapStateToProps, {getShop})(BilletteriePage));